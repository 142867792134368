.demo-container{
  width: 90%;
  margin: var(--margin-large) 5%;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: var(--margin-small);

}



.demos {
  /* all: initial; */
  display: flex;
  flex-direction:row;
 
  justify-content: center; 
  text-align: center;
  flex-wrap: wrap;
 
  gap: var(--margin-small);
  max-width: 96%;
  margin: auto;

  
  
}


  .demo-item-services{
    display: flex;
    flex-direction: column;
    align-items: center;    
    border-radius: 10px;
    box-shadow: var(--box-shadow);   
    justify-content: center;  
    width:25vw;   
    height: 250px;
    overflow: hidden;
    position: relative;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size-tiny);
    background-color: var(--secondary-color);
    color:var(--primary-color); 
   

   
  }
  .demo-item-services img{
       
    max-width: 90%;
    max-height: 90%;
    display: block;   
    opacity: 0.8; 
    object-fit: contain;
    
  
}

 
  .demo-item-services div p:last-child{
    margin-top: var(--margin-tiny);
    font-size: var(--body-font-size-small);
    font-weight: bold;

  }

  .demo-item-services div{
    display: none;

  }
  .demo-item-services>div:last-child{
    display: block;
    background-color:var( --accent-color-secondary);
  }


.link-to-site-services {
color: var(--strong-black);
font-family: var(--header-font-family);
font-size: var(--body-font-size);
font-weight: bold;
background-color: var(--accent-color-primary);
border-color: var(--accent-color-primary); 
padding: var(--margin-tiny) var(--margin-small);
margin-top: var(--margin-bento);
display: none;


}

.demo-item-services:hover {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  
  
  opacity: 1;
}
  .demo-item-services:hover div{
    display: block;
    text-align: left;
    
    

  }
  .demo-item-services:hover>div:last-child{
    background-color: var(--accent-color-primary);
  }
  .demo-item-services:hover img{
    display: none;
  }
  .demo-item-services:hover video{
    display: none;
  }
  .demo-item-services:hover .visit-icon{
    display: inline-block;
  }
  .demo-item-services:hover .link-to-site-services{
    display: block;
  }
 
 

    .link-to-site-services:hover{
      
        color: var(--secondary-color);
        font-weight: 400px;
        background-color: var(--primary-color);
        border-color:var(--primary-color);
        cursor: pointer;
      
    }


    @media (width > 430px) and (width<=850px) {
      .demo-container{
        /* height: 120vh; */
        /* justify-content: flex-start; */

      }
      .demos{
        width: 50%;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        
      }
      .demo-item-services{
         
        width: 50vw;   
        height: 200px;
        overflow: hidden;
        position: relative;
        font-family: var(--body-font-family);
        font-size: var(--body-font-size-tiny);
        background-color: var(--secondary-color);
        color:var(--primary-color); 
       
      }
      /* .demoPic-services{
       
        width: 80%;
        height: 80%;
        margin: auto;
           
      
    } */
      
      .demoPic{
       
        width: 80%;
        height: 80%;
           
      
    }
  
  
  
      
    }

    @media (width<=430px) {

      .demo-container{
       /* height: 140vh; */
       justify-content: center;
       width: 90%;
       margin: auto;
      
    }
    .demos{
      flex-direction: column;
      min-width: 100%;
      margin: auto;
     
    }
    .demo-item-services{
       
      width: 100%;  
     
      overflow: hidden;
      position: relative;
      font-family: var(--body-font-family);
      font-size: var(--body-font-size-tiny);
      background-color: var(--secondary-color);
      color:var(--primary-color); 
     
    }
    .demo-item-services p{
      max-width: 80%;
      margin-left: 10% ;

      /* border: 2px solid red; */
    }
  
  }
    
    