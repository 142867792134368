.product-lifecycle-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    /* margin: 32px auto; */
    height: 90vh;
    padding: 3rem;
    gap: 7.5rem;



}

.product-lifecycle-section {
    height: 100vh;

}


/* Additional styles for the other elements inside the container */

.cards {

    display: flex;
    flex-direction: row;
    /* Set flex direction to row */
    justify-content: space-around;
    /* Adjust this based on your layout needs */
    align-items: center;
    position: absolute;
    top: 0;
    left: 20%;




}

.cardMotion {

    display: flex;
    flex-direction: row;
    /* Set flex direction to row */
    justify-content: space-around;
    /* Adjust this based on your layout needs */
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    border: solid 2px var(--logo-background-color-secondary);
    color: var(--secondary-color);
    background-color: var(--accent-color-secondary);
}

.card-item {
    position: relative;
    color: var(--page-backup-color);
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 12.5rem;
    /* Adjust the width based on your design */
    height: 15.625rem;
    /* Adjust the height based on your design */
    border-radius: 0.625rem;

}



.card-button {
    background-color: var(--accent-color-primary);
    height: 2.5rem;
    width: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem 0.3125rem;
    border: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}

.card-button:hover {
    border: 2px solid var(--secondary-color);
    cursor: pointer;
}

.lifecycle-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    position: relative;

    height: 75%;
}

.lifecycle-content h2 {
    color: var(--page-backup-color);
    background-color: var(--secondary-color);
    margin-bottom: var(--margin-medium);
    font-family: var(--header-font-family);

    width: 75%;
    
}

.lifecycle-content p {
    font-family: var(--body-font-family);
    z-index: 96;
    width: 75%;

    text-align: left;
    color: var(--strong-black);
    /* letter-spacing: 1.1; */
    line-height: 1.5;


}

.lifecycle-control-container {
    width: 15.625rem;
    height: 6.25rem;
    top: 11.25rem;
    left: 3.125rem;

}

.card-item-id {
    font-weight: bold;
    font-size: var(--header-h1-font-size);

}

.lifecycle-image-container {
    width: 25rem;
    height: 25rem;

    overflow: hidden;
    border-radius: 0.625rem 1.25rem;

    position: absolute;
    bottom: 0;
    right: -10%;

}
.lifecycle-image-container{
    animation: reveal 1s ease-in-out forwards;
    opacity: 0;
}
.lifecycle-image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  
    z-index: 92;
}

@keyframes reveal {
    from {
        opacity: 0;
        transform: translateY(20px);
        /* Optional: Add a subtle animation effect */
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adjust the width of the second div for smaller screens */
@media (width >700px) and (width <=850px) {
    .product-lifecycle-container {
        gap: 60px;
    }

    .cards {
        position: absolute;
        top: 0;
        left: 10%;
    }

    .card-item {
        width: 150px;
        height: 200px;
    }

    .lifecycle-content {
        width: 70%;
    }

    .lifecycle-control-container {
        width: 200px;
        top: 140px;
        left: 20px;

    }

    .card-button {
        width: 40px;
        height: 30px;
    }
}

@media (width > 430px) and (width <=700px) {
    .product-lifecycle-container {
        gap: 20px;

    }

    .cards {
        position: absolute;
        top: 0;
        left: 10%;
    }

    .card-item {
        width: 150px;
        height: 200px;
    }

    .lifecycle-content {
        width: 70%;
    }

    .lifecycle-control-container {
        width: 200px;
        top: 140px;
        left: 20px;

    }

    .card-button {
        width: 40px;
        height: 30px;
    }

    .lifecycle-content h2 {

        margin-bottom: var(--margin-tiny);
        font-size: var(--header-h4-font-size);
    }

    .lifecycle-content p {
        font-size: var(--body-font-size-small);
    }

    .card-item p {
        font-size: var(--body-font-size-small);

    }

    .card-item-id {
        font-size: var(--header-h2-font-size);
    }
}

@media (width>380px) and (width <=430px) {

    .product-lifecycle-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5%;
        height: 130vh;

    }

    .cards {
        position: absolute;
        top: 0;
        left: 25%;

    }

    .card-item {
        width: 9.375rem;
        height: 12.5rem;
    }

    .lifecycle-control-container {
/*        width: 12.5rem;*/
        width: 15rem;
        top: 8.75rem;
        left: 25%;

    }

    .card-button {
        width: 40px;
        height: 30px;
    }
 

    .product-lifecycle-section h2 {
        width: 90%;

    }

    .lifecycle-content {
     
       
       
        /* display: flex;
        align-items: flex-start; */

        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      
        height: 50%;
        width: 100%;
        margin-left: auto;
        position: relative;
       

    }

    .lifecycle-content h2 {

        margin-bottom: var(--margin-tiny);
        font-size: var(--header-h4-font-size);
        width: 100%;
        height: 20%;
        text-align: left;

    }

    .lifecycle-content p {
        font-size: var(--body-font-size);
        width: 100%;


        text-align: left;
    }
    

    .card-item p {
        font-size: var(--body-font-size-small);

    }

    .card-item-id {
        font-size: var(--header-h2-font-size);
    }

    .lifecycle-image-container {
        width: 150px;
        height: 150px;

        overflow: hidden;


        position: absolute;
        bottom: 0;
        right: 0;
        margin-left: 20px;
       
       
        

    }

    .lifecycle-image-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation: reveal 1s ease-in-out forwards;
        opacity: 0;
        z-index: 92;
    }

}

@media (width<=380px) {
    .product-lifecycle-section {
        height: 120vh;
    }

    .product-lifecycle-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5%;


    }

    .cards {
        position: absolute;
        top: 0;
        left: 25%;
    }

    .card-item {
        width: 9.375rem;
        height: 12.5rem;
    }


  

    .lifecycle-control-container {
        width: 200px;
        top: 140px;
        left: 25%;

    }

    .card-button {
        width: 40px;
        height: 30px;
    }

    .product-lifecycle-section h2 {
        width: 80%;

    }
    .lifecycle-content {
        width: 100%;

        height: 60%;
        
    }

    .lifecycle-content h2 {

        margin-bottom: var(--margin-tiny);
        font-size: var(--header-h4-font-size);
        height: 35%;
        width: 100%;
    }

    .lifecycle-content p {
        font-size: var(--body-font-size-tiny);
        width: 100%;


        text-align: left;

    }
    .lifecycle-image-container {
        width: 150px;
        height: 150px;

        overflow: hidden;


        position: absolute;
        bottom: 0;
        right: -30%;
        margin-right: 20px;

    }

    .lifecycle-image-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation: reveal 1s ease-in-out forwards;
        opacity: 0;
        z-index: 92;
    }

    .card-item p {
        font-size: var(--body-font-size-small);

    }

    .card-item-id {
        font-size: var(--header-h2-font-size);
    }
}