
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


:root{

  --screen-large:1200px;
  --screen-medium-large:1000px;
  --screen-medium:800px;
  --screen-small:600px;
  --primary-color: #F5F5F5;
  --secondary-color: #474554; 
  --secondary-color-light: #888;
  --text-color: #474554;
  --accent-color-primary: #c7e6d7;   
  --accent-color-primary-light :#d7e8df;
  --accent-color-secondary: #FFD8B2;  
  --accent-color-secondary-strong: #ebb176;  
  --strong-black: #000000;
  --strong-white: #FFFFFF;
  --logo-font-color-primary:#695306;
  --logo-font-color-primary:#ffd230;
  --logo-font-color-secondary: #060601;
  --logo-font-color-accent:#4ddde0; 
  --logo-background-color:#dddddd;
  --logo-background-color-secondary:#896862;
  --card-background-color:#f0f0f0;
  --page-backup-color: #f2f2e9;

  :lang(en) {
    --header-font-family: 'Playfair Display', sans-serif;
    --body-font-family: 'Montserrat', serif;
    --logo-font-family: 'Squada One', sans-serif;
  };
  :lang(am){
    /* @font-face {
      font-family: 'Seat-Regular';
      src: url(${SeatRegularFont}) format('woff2');
    } */
    --header-font-family: 'Seat-Regular', sans-serif;
    --body-font-family: 'Seat-Regular', sans-serif;
    --logo-font-family: 'Squada One', sans-serif;
  }
 

  html {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    html {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 480px) {
    html {
      font-size: 0.75rem;
    }
  }


  --header-main-font-size: 2.8rem;
  --header-h1-font-size: 2.5rem;
  --header-h1-small-font-size:2rem;
  --body-font-size: 1rem;
  --body-font-size-small: 0.89rem;
  --body-font-size-tiny: 0.875rem;
  --body-font-size-tiny-tiny: 0.75rem;
  --body-font-size-veryTiny: 0.625rem;
  --header-h3-font-size: 1.33rem;
  --header-h4-font-size: 1.167rem;
  --header-h2-font-size: 2rem;

  --logo-size : 5rem;
  --margin-large: 4rem;

  --margin-veryLarge: 6rem;
  --margin-huge: 8.875rem;
  --margin-medium: 2rem;
  --margin-small: 1.33rem;
  --margin-tiny: 0.625rem;
  --margin-bento: 5px;

  --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  --box-shadow-white: 0 4px 12px rgba(255, 255, 255, 0.7);
  --box-shadow-light: 0 4px 8px rgba(0, 0, 0, 0.3);
  --box-shadow-orange: 4px 4px 12px rgba(255, 216, 178, 0.5);
  --box-shadow-cyan: 4px 4px 12px rgba(158, 244, 244, 0.7);


  
}

body {
  
  -webkit-user-select: none; /* Safari and other WebKit browsers */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
  margin: 0 1.33rem;
  font-family: var(--header-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  overflow-x: hidden;
  
  
  
}
h1,h2,h3,h4,h5,h6 {
  font-family: var(--header-font-family);
 
}
h1,h2{
  letter-spacing: 4px;

}
h1{
  font-weight:bolder;
  color:#222;
}
p, ul, li {
  font-family: var(--body-font-family);
}
