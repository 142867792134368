
.main-container {
   
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    font-size: var(--body-font-size);
    overflow: hidden;
  }

.sub-container-home{
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.sub-header{
    
  /* font-family: var(--logo-font-family); */
  font-family: var(--header-font-family);
font-size: var(--header-h1-font-size);
font-weight: bold;

/* background: linear-gradient(to right, var(--logo-background-color),var(--logo-background-color-secondary)); */
/* background-color: var( --logo-background-color-secondary); */
width: 50%;
border-radius: 20px 10px;
padding: 10px 10px;
margin: var(--margin-large) auto var(--margin-small) auto;
color: var(--secondary-color);


}



.home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 

 
}
.landing-page
{
  justify-content: center;
  align-items: center;
  
}

.pitchHome {
  margin-top: var(--margin-medium);
  position: relative;
  height: 200vh;
  overflow: hidden;
  width: 100%;
  font-family: var(--logo-font-family);
  /* color: var(--logo-font-color-primary); */
  color: var(--primary-color);

 
}
.pitchHome h3 span:first-child{
  font-size: var(--header-main-font-size);
}


.pitchHome>div:first-child {
  /* position: absolute;
  top: 0; */
  display: flex;
  flex-direction: column;
  gap: var(--margin-medium);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: space-evenly;

  background-color: var( --logo-background-color);

}


.layered-pitch {
 
  width: 100%;
  height: 100vh;
  background-color: var( --logo-background-color);
  
 
}
.pitchDown{
  animation: moveDown 2s ease-out 1s 1 forwards;
 
}
.pitchUp{
  animation: moveUp 2s ease-out 1s 1 forwards;
 
}

@keyframes moveDown {
  from {
    transform: translateY(0%); /* Start from the right of the container */
  }
  to {
    transform: translateY(100%); /* Move to the far left of the container */
  }
}

@keyframes moveUp {
  from {
    transform: translateY(0%); /* Start from the right of the container */
  }
  to {
    transform: translateY(-100%); /* Move to the far left of the container */
  }
}

.layered-pitch img {
  max-width: 50%;
  max-height: 100%;
  object-fit: contain;
}

.pitchHome>div h1{
    
    width: 80%;
   
    margin: auto;
    font-size: var(--header-main-font-size);
    font-weight: bold;
    text-transform: uppercase; 
    text-align: center;
  
}

.services-animate {
  display: inline-block;
  margin-right: var(--margin-small);
  font-size: 16px;
  font-weight: bold;
  color: var(--strong-black);
}


.hidden{
  display: none;
}


@keyframes slide-in {
  from {
    transform: translateX(100%); /* Start from the right of the container */
  }
  to {
    transform: translateX(-100%); /* Move to the far left of the container */
  }
}
.intro-container {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin:  auto;
  padding-bottom: var(--margin-small);
 
   color: var(--secondary-color);

  
}
.intro-subcontainer {
    display: flex;
    flex-direction: column;
    gap: var(--margin-small);
    justify-content: center;
    align-items: left;
    text-align: left;
    width: 90%;
    margin: var(--margin-small) auto;
    align-items: center;
    justify-content: center;
    
  }
  .intros {
    display: flex;
    gap: var(--margin-veryLarge);
    justify-content: center;
    align-items: center;
    
  }
  .intros img{
    width: 50%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: var(--box-shadow-light);
  }
  .intro-item{
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    margin-bottom: var(--margin-small);
    padding: auto auto var(--margin-small) auto;
    /* border: 2px solid red; */
  
    
    /* flex-wrap: wrap; */
    

  }
  .intro-item .quick-facts{
    height: 30vh;
  }
  .intro-item h3{
    font-size: var(--header-h3-font-size);
    margin: auto auto var(--margin-tiny) auto;
    display: block;
    

  }
  .intro-item ul{
    list-style: none;
    font-size: var(--body-font-size-small);
  }
  .intro-item ul>li{
    display: block;
    text-align: center;
    margin: auto var(--margin-medium) var(--margin-tiny) auto;
    box-shadow: var(--box-shadow);
    padding: var(--margin-tiny);
    border-radius: 5px 2px;
    /* border:2px solid #aaffaa; */
    border:2px solid #c7e6d7;
    
   
  }
  .intro-item ul>li:nth-child(even){
    border:1px solid #FFD8B2;
    /* border: 2px solid #ffaaaa; */
    
  }
  .intro-item ul>li:nth-of-type(1){

  }
 
  .customized-item {
    width: 75%;
    margin: auto;
    margin-bottom: var(--margin-small);
  }
  .customized-item ul>li{
    display: inline-block;

  }
/* -------------------------- */  

.intro-top{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
  margin-left: var(--margin-large);
  margin-right: var(--margin-large);
}
.intro-top >div{
  flex: 1;
}




.intro-picture,
.intro-motto{
  margin: var(--margin-small) auto;
}
.intro-motto {
  text-align: center;
}



.intro-picture {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;   
  width: 50%;
  margin: auto auto var(--margin-small) auto;
 
   }



.intro-picture >div{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
   border-radius: 20px 40px;
 
   padding: var(--margin-small);
  
}
.intro-picture >div>p {
  margin-bottom: 5px;
}
.greetings {
  font-size: 14px;
}
.name {
  font-weight: bold;
  font-size: 1.4rem;
 
}

.title {
  font-weight:600;


}
.areas {
  font-size: 16px;
}
.intro-skills  {

  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: var(--margin-small);
  align-items: left;
  
}
.intro-skills>div:first-child{
  flex: 1;

}

.intro-skills > div:last-child{
  flex: 4;
  display: flex;
  flex-direction: column;
}

.intro-skills>div:last-child img{
  width: 300px;
  height: 200px;
  margin-right: var(--margin-small);
}

.intro-picture img {
  border-radius: 50%;

}




.bubble-main {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    transition: top 1s ease-in-out, left 1s ease-in-out;
  }
  
  .big-bubble {
    animation: pulse-big-small 2s infinite alternate;
    /* background-color: #c8aaa5; */
    width: 40px;
    height: 40px;
    
  }
  
  .small-bubble {
    /* background-color: #f1dc8e; */
    animation: pulse-big-small 3s infinite alternate;
    width: 20px;
    height: 20px;
}
  
.medium-bubble {
 
  animation: pulse-medium 2s infinite alternate;
  width: 30px;
  height: 30px;
  /* top:20%;
  left: 50%; */

}

.bubble-mini{
  visibility: hidden;
}
.bubble-mini>div{
  animation: pulse-big-small 2s infinite alternate;
}
.bubble-mini>div:last-child{
  animation: pulse-medium 2s infinite alternate;

}



  @keyframes pulse-big-small {
    from {
      transform: scale(1.5);
    }
    to {
      transform: scale(1);
      opacity: 0;
    }
  }

  

  @keyframes pulse-medium {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(2);
      opacity: 0; /* Vanish effect */
    }
  }
  


.intro-details-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items:center;
    justify-content: center;
    
}
.intro-details{
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items:baseline;
  justify-content: center;
  
}

.intro-section{
   margin-left: var(--margin-large);
   margin-bottom: var(--margin-small);
    
}


.intro-section h3{
    font-size: var(--body-font-size); /*regular font*/  
    font-weight: 400; /*regular font*/
    text-decoration: underline;
    
}
.intro-section ul{
    text-decoration: none;   
}
.intro-section ul>li{
    list-style: none;
    font-size: var(--body-font-size);
    font-weight: bold;
}

.visible {
  display: inline-block;
  text-align: left;
  padding: 10px;
}

.services-container {
  border: solid 4px var(--strong-black);
  width: 80%;
  margin-left: 10%;
  height: 10%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(auto, auto); /* Allow rows to adjust to content height */
  justify-content: center;
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-large);
}

#box-services {
  grid-column: 1; /* Place in the first column */
}

#services {
  grid-column: 2; /* Place in the second column */
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-family:var(--body-font-style);
  line-height: 2;
  height: 300px;
 
}

#box-services > div {
  margin: 5px 0; /* Add margin for spacing between items in box-services */
}

#box-services p {
  margin: 8px 0; /* Add margin for spacing within the paragraphs in box-services */
}

.services-header {
  font-family: var(--header-font-style);
  font-weight: bold;
  font-size: var(--header-h3-font-size);
}
.services-body{
  
  font-weight: 600px;
  font-size: var(--header-h3-font-size);

}

.box {
  background-color: var(--secondary-color-light);
  border-radius: 0px;
}
.box-text{
  color: var(--primary-color);
  font-size: var(--header-h3-font-size);
  font-weight: bold;
  font-family:var(--header-font-style);
  
}

/* .services-demo >div{
 
  width: 90%;
  margin: var(--margin-large) 5%;
  
}
.services-demo >div>div>div{
  margin-bottom: var(--margin-medium);
} */
ul.demo-text{
  list-style: none;
  text-align: left;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size-small);
  /* text-transform:lowercase; */
}
ul.demo-text >li{
  margin-bottom: 10px;
}
ul.demo-text >li:nth-child(even){
  color: var(--logo-background-color);
}

.web-design {
  display: flex;
  flex-direction: column;
  
  /* border: solid 4px #474554; */
}



.showcase-web-design{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  box-shadow: var(--box-shadow);
 
  
 
}
.showcase-web-design > div {
  margin: 10px var(--margin-medium) 10px var(--margin-medium);
  /* border: solid 1px #474554; */
  padding: var(--margin-small) 0;
  
  
}
.showcase-image-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
 
  
}
.showcase-image-container > div {
  align-items: center;
  /* margin-bottom: 10px; */
 
}
.showcase-image-container > div >img{
  max-width: 90%;
  max-height: 300px;
  display: block;
  margin: 0 auto; /* Center the image horizontally within its container */

}

.control-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.visit-icon {
  width: 20px;
  height: 20px;
 

}

.arrow-icon {
  color: black;
  border: solid 2px #474554;
  padding: 2px 30px;
  border-radius: 4px;
  cursor: pointer;
  transform: scale(1);
  margin: 0 50%;
}
.arrow-icon:hover {

  background-color: var(--accent-color-primary);
  

}
.showcase-text-container {
  flex: 1;;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  align-items: center;
  font-family: var(--body-font-family);
  box-shadow: var(--box-shadow);
 
}
/* Add this to your styles or stylesheet */
.affiliations {
 
  height: 70vh;
 
 
}


.affiliations img{
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-right: var(--margin-small);
  box-shadow: var(--box-shadow);
}

.moving img {
 
  animation: scrollImages 5s linear infinite; 
  
} 

@keyframes scrollImages {
  0% {
    transform: translateX(0%);
    border-radius: 0 40px;
    
  }

  50% {
    transform: translateX(100%);
    border-radius: 40px 0;
  }

  100% {
    transform: translateX(0%);
    border-radius: 0 40px;

  }
}

/* .affiliations{
  height: 70vh;
  width: 100%;
  max-width: 70%;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--margin-medium);
  border: 2px solid red;
} */
.affiliations-slider{
  border-top: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
  height: fit;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--margin-small);
  border: 2px solid red;

}
.affiliations-slider:before,
 .affiliations-slider:after{
  content: "";
  position: absolute;
  height: fit;
  width: 150px;
  z-index: 2;

}
.affiliations-slider:after{
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.affiliations-slider:before{
  left: 0;
  top: 0;

}
.affiliations-slider .track {
  display: flex;
  width: max-content;
  animation: scroll-affiliations 5s 5s linear infinite;

}
.affiliations-slider .element{
  width: 150px;
  overflow: hidden;
  border-radius: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
  padding: var(--margin-small);
  margin: 0 var(--margin-tiny);
}
@keyframes scroll-affiliations {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-100%);
  }
  
}
.cfa {
 
  margin: var(--margin-small) auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: 'relative';
  height: 300px;
  width:50%;
 
  box-shadow: var(--box-shadow);

}
.cancel-button {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #FFD8B2;
  width: 30px;
  height: 30px;
  background-color: #474554;
  cursor: pointer;
  filter: grayscale(100%);
}
.cancel-button:hover{
  filter: grayscale(0%);
}


.cta-button {
  color: var(--strong-black);
  font-family: var(--header-font-family);
  font-size: var(--header-h3-font-size);
  font-weight: bold;
  background-color: var(--accent-color-primary);
  border-color: var(--accent-color-primary); 
  width: 300px;
  height: 20%;
  border-radius: 10px 5px;
  transition: all 0.5s ease;
  
 
  }
 
  .cta-button-small {
    color: var(--strong-black);
    font-family: var(--header-font-family);
    font-size: var(--body-font-size-small);
    font-weight: bold;
    background-color: var(--accent-color-primary);
    border-color: var(--accent-color-primary); 
    padding: var(--margin-tiny) var(--margin-small);
    margin-top: var(--margin-tiny);
    
   
    }
    .cta-contactus {
      color: var(--secondary-color);
      background-color: var(--logo-font-color-primary);
      font-family: var(--header-font-family);
      font-size: var(--body-font-size-small);
      font-weight: bold;
      border: 1px solid var(--secondary-color);
      padding: var(--margin-bento) var(--margin-tiny);
      border-radius: 5px;
      animation: contactBorderAnimation 2s infinite;
      

    }
    .cta-button:hover, 
.cta-button-small:hover {
  color: var(--accent-color-primary);
  font-weight: 400px;
  background-color: var(--secondary-color);
  border-color:var(--secondary-color);
  cursor: pointer;
} 
.cta-button-home:hover{
  color: var(--accent-color-primary);
  font-weight: 400px;
  background-color: var(--secondary-color);
  cursor: pointer;
  animation: contactBorderAnimation 2s ease-in-out ;
  
  
  

}

.cta-contactus:hover {
  border: 2px solid var(--secondary-color);
  cursor: pointer;
 
  background-color: var(--accent-color-primary);

}

@keyframes contactBorderAnimation {
  0%, 100% {
    border-radius: 10px 20px;
  }
  50% {
    border-radius: 20px 10px;
  }
  90% {
    border-radius: 10px 20px;
  }
}

  .showcase-app-design {
    
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
  }
 
  .showcase-app-design >div:first-child{
    flex:1;
    display: flex;
    flex-direction: row;
  
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  
  }
  .top >div{
    box-shadow: var(--box-shadow);;
  }
  
  .top >div:last-child{
    margin-right: var(--margin-large);
    font-family: var(--header-font-family);
    font-size: var(--header-h3-font-size);
  }
  .images-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    align-items: center;
    margin-right: var(--margin-large);
   
  
    
  }
  
  .images-container >div:first-child {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    margin-bottom: var(--margin-small);
    padding: var(--margin-small) var(--margin-large);
   
  
  }
  
  .images-container >div:first-child >div {
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: var(--box-shadow);;
    width: 150px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .images-container >div:first-child >div:first-child {
  
    margin-right: var(--margin-small); 
   
  }
  
  .images-container >div:first-child >div  >img {
    width: 90%;
    height: 90%;
  }
  
 



.transit-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--accent-color-primary);
  transform-origin: bottom;
  z-index: 100;

}
 .transit-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: #FFD8B2; */
  /* background-color: #fafadf; */
  background-color: var(--accent-color-primary);
  transform-origin: top;
  z-index: 100;

} 


.reach-you-form{
     
  height: 100%;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;



 
}
.form-step-container {
  
  height:80%;
  width: 100%;

 
}


.form-step{
  
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-family: var(--body-font-family);
 



}


.form-step input[type="text"],
.form-step input[type="tel"]
{
  

  width: 100%;
  height: 100%;
  border: none;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size-small);
 
}

.form-step textarea {
  width: 90%;
 
  border: none;
  padding: var(--margin-tiny);
  background-color: #eceee0;
 

  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 10px 5px;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size-small);
}
.form-step textarea:focus{
  border:  2px solid var(--secondary-color);
}



.form-step-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in; /* Adjust duration and timing function as needed */
  
}

.form-step-container.active {
  opacity: 1;
  visibility: visible;
  
}
.continue-button:hover{
  border: 2px solid var(--secondary-color);
  border-radius: 4px 2px;

}

@media (width>600px) and (width<= 800px) {
  .layered-pitch img {
    width: 100%;
   
  }
  
}

@media (width > 430px) and (width<= 600px) {
  .layered-pitch img {
    width: 100%;
  
  }
}
@media (width>700px) and (width<= 800px){
  .pitchHome>div>h1{
    width: 70%;
    

  }
  .pitchHome>div>h3{
    width: 75%;
  }
  .pitchHome>div:first-child{
    justify-content: center;
    align-items: center;
  }
  
}
@media (width>600px)and(width<=700px){
  .pitchHome>div>h1{
    width: 50%;
    font-size: var(--header-h2-font-size);
  }
  .pitchHome>div>h3{
    width: 50%;
    /* font-size: var( --header-h4-font-size); */
  }
  .pitchHome>div:first-child{
    justify-content: center;
    align-items: center;
    
  }
}
@media (width>430px) and (width<=600px){
  .pitchHome>div>h1{
    width: 50%;
   font-size: var(--header-h2-font-size);
  }
 
  .pitchHome>div:first-child{
    justify-content: center;
    align-items: center;
    
  }
  .pitchHome>div>h3{
    width: 90%;
    font-size: var( --body-font-size);
  }
}

@media (width < 1000px) {
  .customized-item {
    width: 90%;
    margin: auto;
  }
  
}
@media (width>700px) and (width<=800px) {
  .cfa{
    width: 60%;
    margin: var(--margin-medium) auto;
  }
  
}
@media  (width<=700px) {
  .cfa{
    width: 80%;
    margin: var(--margin-medium) auto;
  }
  
}

@media (width>380px) and (width<=430px){
  .pitchHome {
    height: 70vh;
    
   
  }
  .pitchHome>div:first-child,
  .layered-pitch {
    height: 50%;
   
    
}
.pitchHome>div:first-child img,
  .layered-pitch img{
    object-fit: cover;
  }
.pitchHome>div h1{
 font-size: var(--header-h3-font-size);
  width: 85%;
  margin: auto;
}
.pitchHome h3 {
  width: 90%;
  margin: auto;
font-size: var( --body-font-size-tiny);
}
.company-name{
  font-size: var(--header-h3-font-size);
}


.intros {
  gap: var(--margin-large);
  width: 90vw;
   
}

.intros>div:last-child{

height: 100%;

}
.intros img{
width: 80%;
height: 50%;
}
.customized-item{

  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto ;
  
  }
  .intro-item .quick-facts{
    height: 40vh;
 
  }
  .intro-item h3{
    margin: auto auto var(--margin-tiny) var(--margin-tiny);
    

  }
.intro-item ul{
font-size: var(--body-font-size-tiny-tiny);
width: 100%;
}
.customized-item ul>li{
  display: inline-block; 
  margin: auto var(--margin-tiny) var(--margin-tiny) auto ;
}
.affiliations{
  height: 50vh;
 }

.affiliations img{

  width: 80px;
  height: 80px;
  margin-right: var(--margin-tiny);
  margin-bottom: var(--margin-tiny);
 
}

 .affiliations h2{
  width: 80%;
 
 }
 .bubble-main{
  visibility: hidden;
 }
 .bubble-mini{
  visibility: visible;
 }


@keyframes scrollImages {
  0% {
    transform: translateX(-50%);
    border-radius: 0 40px;
    
  }

  50% {
    transform: translateX(150%);
    border-radius: 40px 0;
  }

  100% {
    transform: translateX(-50%);
    border-radius: 0 40px;

  }
}



}
@media (width<=380px){
  .pitchHome {
    height: 75vh;

  }
 
  .pitchHome>div:first-child,
  .layered-pitch {
    height: 50%;
}
.pitchHome>div h1{
 font-size: var(--header-h4-font-size);
  width: 90%;
  margin: auto;
}
.pitchHome h3 {
  width: 90%;
  font-size: var( --body-font-size-tiny-tiny);
  margin: auto;
}
.company-name{
  font-size: var(--header-h4-font-size);
}
.intro-container h2{
  width: 90%;
  margin: auto;
}
.intro-item{
  margin-left: 2%;
}
.intro-item .quick-facts{
  height: 40vh;
  
}
.intro-item h3{
  margin: auto auto var(--margin-tiny) var(--margin-tiny);
  

}
.intros {
  gap: var(--margin-large);
  width: 90vw;
   
}

.intros>div:last-child{

height: 100%;

}
.intros img{
width: 70%;
height: 50%;
}
.customized-item{

  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto ;
  
  }
.intro-item ul{
font-size: var(--body-font-size-tiny-tiny);
width: 100%;
}
.customized-item ul>li{
  display: inline-block; 
  margin: auto var(--margin-tiny) var(--margin-tiny) auto ;
}
.affiliations{
  height: 50vh;
 }
.affiliations img{
 
  width: 80px;
  height: 80px;
  margin-right: var(--margin-tiny);
  margin-bottom: var(--margin-tiny);
 
}

.affiliations h2{
  width: 80%;
 
 }

 @keyframes scrollImages {
  0% {
    transform: translateX(-50%);
    border-radius: 0 40px;
    
  }

  50% {
    transform: translateX(150%);
    border-radius: 40px 0;
  }

  100% {
    transform: translateX(-50%);
    border-radius: 0 40px;

  }
}



}



