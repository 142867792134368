.motto {
    padding: 10px;
  }
  .motto h1{
    font-family: var(--header-font-family);
    font-size: var(--header-main-font-size);
    width: 80%;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .bottom {
    margin-top: var(--margin-small);
  }
  .bottom >div>div{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    width: 450px;
    height: 250px;
    
  }
  
  .bottom >div>div img{
    width: 90%;
    height: 90%;
  }
  
  /*brand design*/
  
  .showcase-brand-design {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
  }
  .images-brand {
    width: 300px;
    height: 300px;
  
  }
  .images-brand img {
    width: 90%;
    height: 90%;
   
  }
  
  /*office software solutions*/
  .showcase-ss-ai {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
  }
  .ssai-top {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
    margin-top: var(--margin-small);
  }
  .ssai-images{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid green;
    width: 300px;
    height: 300px ;
  
  }
  .ssai-top >div {
    border: 2px solid black;
  }
  .ssai-images >div:first-child{
    border: 2px solid black;
    border-radius: 10px;
    width: 300px;
    height: 300px ;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: var(--margin-small);
  
  }
  .ssai-images >div:first-child img{
    width: 90%;
    height: 90%;
  }
  
  .ss-ai {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
  }
  .ss-ai>div:first-child {
    margin-right: 42px;
  }
   .ssai-bottom {
    border: 2px solid black;
    padding: 100px;
  } 
  .sub-portfolio {
    display: flex;
    flex-direction: column;
    padding: var(--margin-tiny);
  }
  

  .demo-item{
    display: flex;
    flex-direction: column;
    align-items: center;    
    border-radius: 10px;
    box-shadow: var(--box-shadow);   
    justify-content: center;  
    width:25vw;   
    height: 250px;
    overflow: hidden;
    position: relative;
    font-family: var(--body-font-family);
    font-size: var(--body-font-size-tiny);
    background-color: var(--secondary-color);
    color:var(--primary-color); 
    padding: var(--margin-veryTiny);
   

   
  }
  .demo-item img{
       
    width: 90%;
    height: 90%;
    display: block;   
    opacity: 0.8; 
    object-fit: contain;
    
  
}

 
  .demo-item div p:last-child{
    margin-top: var(--margin-tiny);
    font-size: var(--body-font-size-small);
    font-weight: bold;

  }

  .demo-item div{
    display: none;

  }
   .download-message{
    display: block;
    position: absolute;
    top:0%;
    left: 50%;
    background-color: var( --logo-font-color-primary);
    color: var(--strong-black);
    font-size: var(--body-font-size);
    height: 10%;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }
  .demo-item>div:last-child{
    display: block;
    background-color:var( --accent-color-secondary);
  }
 
  .demo-item:hover {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  
  
  opacity: 1;
  padding: var(--margin-veryTiny);
}

  .demo-item:hover div{
    display: block;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: var(--margin-small);
    padding: var(--margin-bento);
   
    
    

  }

  .demo-item .link-to-site-services{
    font-size: var(--body-font-size-tiny);
  }

  .demo-item:hover>div:last-child{
    background-color: var(--accent-color-primary);
  }
  .demo-item:hover img{
    display: none;
  }
  .demo-item:hover .visit-icon{
    display: inline-block;
  }
  .demo-item:hover .link-to-site-services{
    display: block;
  }
 

  .demo-item-info {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    
    
    box-shadow: var(--box-shadow);
   
    justify-content: center;
    padding: var(--margin-medium) ;
   
  }
  .demo-text{
    padding: var(--margin-tiny) ;
    left: 25px;
    top:25px;
  }
  .demo-item-info>ul>li:nth-child(odd){
    color: var(--primary-color);

  }
  .demo-item-info>ul>li:nth-child(even){
    color: #ffd230;
  }
  .demo-item{
    width: 100%;
  }
  .demo-item p,
  .demo-item-info p{
    display: none;
  
  }
  

  .demoPic{
       
    max-width: 90%;
    margin: auto 5%;
    max-height: 250px;
    display: block;    
  
  }
  
  .demoAppPic {
  max-width: 40%;
  margin: auto 5%;
  max-height: 250px;
  display: block; 
  }
  
  .link-to-site {
  color: var(--strong-black);
  font-family: var(--header-font-family);
  font-size: var(--body-font-size-small);
  font-weight: bold;
  background-color: var(--accent-color-primary);
  border-color: var(--accent-color-primary); 
  padding: var(--margin-bento) var(--margin-tiny);
  margin-top: var(--margin-veryTiny);
  display: none;
  max-height: 80px; 
  
  
  }

  .link-to-site:last-of-type {
    color: var(--strong-black);
    font-family: var(--header-font-family);
    font-size: var(--body-font-size-small);
    font-weight: bold;
    background-color: var(--accent-color-primary);
    border-color: var(--accent-color-primary); 
    padding: var(--margin-bento);
    margin-top: var(--margin-veryTiny);
    display: none;
    max-height: 80px;
  
    
    
    }

   .visit-icon{
    max-width: 30px;
    max-height: 30px;
  } 
  
  .demo-item:hover p,
  .demo-item-info:hover p{
    display: block;
  
  }
  .demo-item:hover img,
  .demo-item-info:hover img{
    display: none;
  }
  .demo-item:hover .visit-icon,
  .demo-item-info:hover .visit-icon{
    display: inline-block;
  }
  .demo-item:hover .link-to-site,
  .demo-item-info:hover .link-to-site{
    display: block;
  }
  .demo-item-info:hover{
    flex-direction: column;
  }
  
  
    .link-to-site:hover{
      
        color: var((--secondary-color));
        font-weight: 400px;
        background-color: var(--primary-color);
        border-color:var(--primary-color);
        cursor: pointer;

      
       
      
    }
    .bento-ssai
    {
      height: 80vh;
      width: 80%;
      margin: auto;
      
      box-shadow: var(--box-shadow);
      display: flex;
      gap:var(--margin-bento);
      padding: var(--margin-bento);
      border-radius: 10px 20px;
      position: relative;
    
    }
    .bento-left{
      width: 70%;
    }
    .bento-right,
    .bento-right-brand{
      width: 30%;
    }
    .bento-right>div p:first-child,
    .bento-right-brand>div p:first-child{
      font-size: var(--body-font-size-tiny);
    }
    .bento-right>div p:last-child,
    .bento-right-brand>div p:last-child{
      font-size: var(--body-font-size-tiny-tiny);
      color: var(--logo-font-color-primary);
      background-color: var(--secondary-color);
    }
    
    .bento {
      height: 90vh;
      width: 80%;
      margin: auto;
      
      box-shadow: var(--box-shadow);
      display: flex;
      gap:var(--margin-bento);
      padding: var(--margin-bento);
      border-radius: 10px 20px;
      position: relative;
    
    }
   
    .bento>div{
      margin-left: 0;
      /* border: 2px solid var(--secondary-color);
      box-shadow: var(--box-shadow-light); */
      
    }
  .bento>div:first-child{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* border: 2px solid yellowgreen; */
    width: 60%;
    gap: var(--margin-bento);
  }
  .bento>div:last-child{
    width: 40%;
    /* border: 2px solid yellowgreen; */
  }
 
  .bento>div>div>div{
    box-shadow: var(--box-shadow);
    border: 1px solid var(--secondary-color);

  }
  .selected-menu{
    background-color: var(--secondary-color);
    color: var(--logo-font-color-primary)
  }

  .brand-bento-4grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    gap: var(--margin-bento);
    height: 65%;
    overflow: hidden;
    border-radius: 4px 8px;
  }
  .grid-flexed{
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-tiny);
    height: 65%;

  }
  .bento-top{
    width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-radius: 4px 8px;
  border: 1px solid #474554;
  }
  .grid-flexed-top{
    height: 45%;
  }
  .brand-bento-4grid>div{
    
      border-radius: 4px 8px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      gap: var(--margin-tiny);
      display: flex;
      justify-content: center;
      align-items: center;
       
  }
  .grid-flexed>div{
    width: 49%;
    height: 50%;
    border-radius: 4px 8px;
    overflow: hidden;
  }
  
  .bento-ssai img,
  .bento img{
    
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(0);
      /* transition: filter 0.5s ease-in-out; */
        
  }
  .bento-hovered-container{
    /* max-width: 80%;
    max-height: 100%;  */
    position: absolute;
    /* transform: scale(0.6); */ 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 10px 20px;
    box-shadow: var(--box-shadow-cyan);
    z-index: 95;
    pointer-events: none;  
    
  
  }
  .bento-hovered-container img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .fade-image {
    max-width: 100%;
    max-height: 55%;
    object-fit: contain;
    /* width: auto;
    height: auto; */
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Add a smooth fade transition */
  }
  .fade-image-app {
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
    /* width: auto;
    height: auto; */
    opacity: 1;
    transition: opacity 0.5s ease-in-out; /* Add a smooth fade transition */
  }

  
  .fade-transition {
    opacity: 0;
  }

 
  
  .bento-right,
  .bento-right-brand{
    display: flex;
    flex-direction: column;
    gap:var(--margin-bento);
    border: 1px solid var(--secondary-color);
    border-radius: 4px 8px;

  }
  .bento-right>div,
  .bento-right-brand>div{

    border-radius: 4px 8px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    
  }
  .bento-right>div:first-child,
  .bento-right-brand>div:first-child{
  height: 70%;
  }
  .bento-right>div:first-child img,
  .bento-right>div:first-child img
  {
    /* width: 100%;
    height: 100%; */
    object-fit: cover;
    border-radius: 4px 8px;
    /* object-fit: cover; */
  }
  .bento-right>div:last-child,
  .bento-right-brand>div:last-child{
    height: 30%;
    border-top: 1px solid var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
  .bento-menu-webApp{
    width: 25%;
  }

  .bento-menu {
    border:1px solid var(--secondary-color);
    border-radius:4px 8px;
    padding: 4px;
    font-size: var(--body-font-size-small);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bento-menu:hover{
    /* background-color: var(--accent-color-primary); */
    cursor: pointer;
    /* scale: 1.1; */
    border-width: 2px;
  }

  
  .ssai-bento-2grid{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    gap: 5px;
    height: 45%;
    overflow: hidden;
    border-radius: 4px 8px;
  }
  .ssai-bento-2grid>div{
    overflow: hidden;
      }
  .ssai-bento-2grid>div img{
    object-fit:fill;
  }
  .webApp-bento-3grid{
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 5px;
    overflow: hidden;
    border-radius: 4px 8px;


  }
  .webApp-bento-3grid>div{
    
    border-radius: 4px 8px;
    /* width: 99%; */
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
     
}
  .ssai-bento-2grid>div{
    
      border-radius: 4px 8px;
      /* width: 99%; */
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
       
  }
  .ssai-bento-2grid>div:last-child{
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
  .bento-img-container{
    height: 45%;
  }
  .circular-gradient{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    bottom: 17%;
    left: 15%;
  }
  .bento-top>div:last-child{
    max-width:75%;
  }
  .reach-you-form-container{
    width:60%
  }
 
  @media (width>750px) and (width<960px) {
    .motto h1{
      
      font-size: var(--header-h1-font-size);
    
  }
}
@media (width>620px) and (width<=750px){
  .motto h1{
    font-size: var(--header-h1-small-font-size);
    width: 90%;
  }
  .demo-item-info ul{
    font-size: var(--body-font-size-tiny);
}
.demo-text{
  left: 10px;
}
}
@media (width>430px) and (width<=620px){
  .motto h1{
    
    width: 90%;
    font-size: var(--header-h2-font-size);
  }
  
  .demo-item-info ul{
    font-size: var(--body-font-size-tiny);
}
.demo-text{
  left: 10px;
}
}

@media (width>380px)and (width<=430px){
  .motto h1{
    
    width: 90%;
    font-size: var(--header-h3-font-size);
  }
  .sub-portfolio h2{
    font-size: var(--header-h3-font-size);
    
    width: 90%;
  }
  .webApp-bento-3grid{
    width: 100%;
    height: 80vh;
    display: flex;
   flex-direction: column;
    gap: 5px;
    overflow: hidden;
    border-radius: 4px 8px;


  }
  .bento{
    height: 100vh;
    width: 90%;
    flex-direction: column;
  }
  .bento-menu-webApp{
    width: 100%;
    
  

  }
  .bento-menu{
    padding: 10px 4px;
  }
  
  
  .webApp-bento-3grid>div{
    
    border-radius: 4px 8px;
    /* width: 99%; */
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
     
}
.demo-item div p:last-child{
  margin-top: var(--margin-bento);
}
  .demo-item:hover div{
 
    gap: var(--margin-bento);
   
    
    

  }


.webApp-bento-3grid .demo-item,
.webApp-bento-3grid .demo-item-info{
  min-height: 32%;
  margin: var(--margin-bento) auto;
  padding: var(--margin-bento);
  display: flex;
  justify-content: center;
  align-items: center;
}
.bento img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.demo-item>div{
  width: 100%;
  
}
.demo-item>div> p{

  font-size: var(--body-font-size-tiny-tiny);
}
.demo-item:hover{
  justify-content: flex-start;
}

/* .links-webApp{
  display: flex;
  width: 100%;
  flex-direction: row;
} */
.link-to-site {
  color: var(--strong-black);
  font-family: var(--header-font-family);
  font-size: var(--body-font-size-tiny-tiny);
  padding: var(--margin-bento) ;
  margin: var(--margin-bento) auto;  
  
  }
  .link-to-site:last-of-type {
    color: var(--strong-black);
    font-family: var(--header-font-family);
    font-size: var(--body-font-size-tiny-tiny);
    padding: var(--margin-bento);margin: var(--margin-bento) auto;  
    
    }
.demo-item-info {
  
 width: 100%;

}
.demo-text{
  padding: var(--margin-tiny) ;
  left: 10px;
  top:25px;
}
.demo-item-info ul{
  font-size: var(--body-font-size-tiny);
}

.bento-ssai{
  display: flex;
  width: 90%;
  flex-direction: column;
  
  
}
.bento-left,
.bento-right{
  width: 100%;
}
.bento-menu-ssai{
  width: 100%;
  
}


.bento-img-container{
  display: flex;
  justify-content: flex-start;
  height: 60%;
  padding: var(--margin-bento);

 
}
.bento-img-container h3{
  font-size: var(--body-font-size);
  margin-bottom: var(--margin-tiny);
}

.bento-img-container p{
  font-size: var(--body-font-size-small);
  text-align: left;
}



.ssai-bento-2grid{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40%;
 
  gap: var(--margin-tiny);
  
}
.ssai-bento-2grid>div:last-child{
  max-height: 50%;
  padding: var(--margin-bento);
}

.bento-left-brand{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--margin-tiny);
  height: 70%;
}
.bento-right-brand{
  height: 30%;
  min-width: 100%;
  font-size: var(--body-font-size-tiny);
}



.bento-top{
  display: flex;
 
  height: 50%;

}

.bento-top>div:first-child{
  min-width: 50%;
}
.bento-top>div:last-child{
  max-width:50%;
}
.bento-top h3{
  font-size: var(--body-font-size-small);
}
.bento-top p{
  font-size: var(--body-font-size-tiny);
}
.bento-right{
  /* width: 0; */
  visibility: hidden;
}
.brand-bento-4grid,
.grid-flexed{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.grid-flexed>div{
  width: 100%;
  height: 100%;
  border-radius: 4px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.bento-hovered-container{
 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bento-hovered-container img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.cta-button {

  width: 80%;
  height: 20%;
  font-size: var(--header-h4-font-size);

  }
  .circular-gradient{
    height: 40px;
    width: 40px;
    border-radius: 50%;
   
    bottom: -5%;
    left: 15%;
  }
  .reach-you-form-container{
   
    width: 90%;
  }
  .form-step{
    width: 100%;
   
   
  }
  .portfolio-others{
    width: 100%;
    margin-bottom: var(--margin-small);
    
  }
 


}

@media (width<=380px){
  .motto h1{
    
    width: 90%;
    font-size: var(--header-h3-font-size);
  }
  .sub-portfolio h2{
    font-size: var(--header-h4-font-size);
   
    width: 90%;
  }
  .webApp-bento-3grid{
    width: 100%;
    height: 100vh;
    display: flex;
   flex-direction: column;
    gap: 5px;
    overflow: hidden;
    border-radius: 4px 8px;


  }
  .bento{
    height: 110vh;
    width: 90%;
    flex-direction: column;
  }
  
  .bento-menu-webApp{
    width: 100%;
  

  }
  .bento-menu{
    padding: 15px 4px;
   
  }
  
  .webApp-bento-3grid>div{
    
    border-radius: 4px 8px;
    /* width: 99%; */
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
     
}
.demo-item {
  min-height: 30%;
  margin: 5px auto;
}
.demo-item div p:last-child{
  margin-top: var(--margin-bento);
}
  .demo-item:hover div{
 
    gap: var(--margin-bento);
   
    
    

  }


.webApp-bento-3grid .demo-item,
.webApp-bento-3grid .demo-item-info{
  min-height: 32%;
  margin: var(--margin-bento) auto;
  padding: var(--margin-bento);
  display: flex;
  justify-content: center;
  align-items: center;
}


.bento img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.demo-item>div{
  width: 100%;
  
}
.demo-item>div> p{

  font-size: var(--body-font-size-veryTiny);
}
.demo-item:hover{
  justify-content: center;
}
.demo-item:hover div{
  
  gap: var(--margin-bento);
  
  

}
.link-to-site {
  color: var(--strong-black);
  font-family: var(--header-font-family);
  font-size: var(--body-font-size-tiny);
  padding: var(--margin-veryTiny) var(--margin-small);
  margin: var(--margin-bento) auto;  
  
  }
.demo-item-info {
  
 width: 100%;

  /* padding: var(--margin-tiny) ; */
}
.demo-text{
  padding: var(--margin-tiny) ;
  left: 10px;
  top:25px;
}
.demo-item-info ul {
  font-size: var(--body-font-size-tiny-tiny);
}



.bento-ssai{
  display: flex;
  width: 90%;
  flex-direction: column;
  height: 95vh;
  
  
}
.bento-left,
.bento-right{
  width: 100%;
}
.bento-menu-ssai{
  width: 100%;
  
}

.ssai-bento-2grid{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40%;
 
  gap: var(--margin-tiny);
  
}
.ssai-bento-2grid>div:last-child{
  max-height: 50%;
  padding: var(--margin-bento);
}

.bento-img-container{
  display: flex;
  justify-content: flex-start;
  height: 60%;
  padding: var(--margin-bento);

 
}
.bento-img-container h3{
  font-size: var(--body-font-size-small);
  margin-bottom: var(--margin-bento);
}

.bento-img-container p{
  font-size: var(--body-font-size-tiny);
  text-align: left;
}

.bento-left-brand{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--margin-tiny);
  height: 70%;
}
.bento-right-brand{
  height: 30%;
  min-width: 100%;
}

.bento-top{
  display: flex;
 

  height: 50%;

}

.bento-top>div:first-child{
  min-width: 30%;
}
.bento-top>div:last-child{
  max-width:70%;
}
.bento-top h3{
  font-size: var(--body-font-size-small);
}
.bento-top p{
  font-size: var(--body-font-size-tiny);
}
.bento-right{
  /* width: 0; */
  visibility: hidden;
}
.brand-bento-4grid,
.grid-flexed{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.grid-flexed>div{
  width: 100%;
  height: 100%;
  border-radius: 4px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.bento-hovered-container{
 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bento-hovered-container img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.cta-button {

  width: 80%;
  height: 20%;
  font-size: var(--header-h4-font-size);

  }
  .circular-gradient{
    height: 40px;
    width: 40px;
    border-radius: 50%;
   
    bottom: -5%;
    left: 15%;
  }
 
  
 
  .reach-you-form-container{
   
    width: 90%;
  }
  .form-step{
    width: 100%;
   
   
  }
  .portfolio-others{
    width: 100%;
    margin-bottom: var(--margin-small);
    
  }
 



}



