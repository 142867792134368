/* Add your styling in a CSS file or use a styling library like styled-components */

.sub-header-blog {

  font-family: var(--header-font-family);
  /* font-size: var(--header-h1-font-size); */
  font-size: var( --body-font-size);
  font-weight: bold;
  width: 80%;
  border-radius: 20px 10px;
  padding: 10px 10px;
  margin: var(--margin-tiny) auto var(--margin-tiny) auto;
  /* color: var(--primary-color);
  background-color: var(--secondary-color); */
  text-align: left;
  

}

.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.blog-sub-container {
  display: flex;
  flex-direction: column;
  gap: var(--margin-small);
  padding: 20px;


}

.blog-content {
  width: 90%;
  margin: auto;
  box-shadow: var(--box-shadow);
  font-family: var(--body-font-family);

}

.blog-featured {

  text-align: left;
  padding: var(--margin-medium);
  position: relative;
  overflow: hidden;


}
.blog-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
}

.blog-display img{
  max-width: 50%;
  max-height: 50%;
  margin-bottom: var(--margin-tiny);
  object-fit: contain;
}
.blog-featured a{
  color: var(--strong-black);
    padding: var(--margin-bento);
  margin-right: var(--margin-bento);
 
}
.blog-featured a:hover{
  background-color: var(--accent-color-secondary-strong);
}


.blog-title-container{
  margin: var(--margin-small) auto var(--margin-tiny) auto;
  padding: var(--margin-bento);
  border-radius: var(--margin-bento);
  background-color: var(--strong-black);
  display: flex;
  border: 1px solid var(--secondary-color);
  flex-wrap: wrap;
  width: 90%;
  margin: var(--margin-small) auto var(--margin-tiny) auto;
  text-align: center;
  

}
.blog-title-container h1{
  font-size: var(--header-h2-font-size);
  color: var(--accent-color-secondary-strong);
  width: 90%;
  text-transform: capitalize;


}



.blog-list-header {
  color: var(--strong-black);
  font-size: var(--body-font-size);
  /* background-color: var(--accent-color-secondary); */
  width: 20%;
  margin-bottom: var(--margin-tiny);
}

.blog-decorator-top,
.blog-decorator-bottom {
  width: 150px;
  height: 150px;

  border-radius: 50%;
  position: absolute;



}

.blog-decorator-bottom-small {
  width: 50px;
  height: 50px;

  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: -20px;
  opacity: 1;
  background-color: var(--primary-color);
  z-index: 1;



}

.blog-decorator-top {
  top: -25px;
  left: -25px;
  opacity: 50%;
  background-color: var(--accent-color-secondary-strong);
}

.blog-decorator-bottom {
  bottom: -75px;
  right: -75px;
  opacity: 50%;
  background-color: var(--secondary-color);
  z-index: -1;

}


.blog-featured h4 {
  color: var(--strong-black);
  font-size: var(--body-font-size-small);
  /* background-color: var(--accent-color-secondary); */
  width: 20%;
}

.blog-featured p {
  font-size: var(--body-font-size-small);

}

.blog-list {
  border-top: 1px solid var(--accent-color-secondary);
  border-bottom: 2px solid var(--accent-color-secondary);
  padding: var(--margin-tiny);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;

}

.blog-list ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: var(--margin-small);
  flex-wrap: wrap;




}



.blog-list li {

  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  /* Adjust as needed */
  height: 200px;
  width: 200px;

  /* background-color: #ffffaa; */
  background: linear-gradient(to bottom, #ffffaa, #C7E6D7);

  box-shadow: var(--box-shadow-light);
  font-size: var(--body-font-size-tiny-tiny);

}
.blog-list li h4 {
  margin-bottom: var(--margin-tiny);
  font-size: var(--body-font-size-tiny);
  
}

.blog-list li:hover {

  background: linear-gradient(to bottom, var(--accent-color-secondary), #C7E6D7);
  ;

}

.circular-content {
  padding: 30px;
  /* Adjust as needed */
  text-align: center;
  word-wrap: break-word;


}

.blog-list li:hover h4 {
  font-size: var(--body-font-size-small);

}

.blog-list li:hover p {
  font-size: var(--body-font-size-tiny);
}

@media (width<=430px) {
  .blog-sub-container {
    display: flex;
    flex-direction: column;
  }
  .sub-header-blog {

    
    /* font-size: var(--header-h3-font-size); */
    border-radius: 20px 10px;
    padding: 5px;
    width: 90%;
  
  }
  .blog-title-container{
   
    padding: var(--margin-bento);    
   
    width: 100%;
    text-align: left;
    margin: var(--margin-small) auto var(--margin-tiny) auto;
  
  
  }
  .blog-title-container h1{
    font-size: var(--body-font-size);
    /* color: var(--accent-color-secondary-strong); */
    
  
  }
  .blog-featured h4 {
    
    font-size: var(--body-font-size-tiny-tiny);
    width: 100%;
  }
  
  .blog-featured p {
    font-size: var(--body-font-size-tiny-tiny);
  
  }
  
  .blog-list {

    display: flex;
    flex-wrap: wrap;


  }

  .blog-list ul {
    list-style: none;
    font-size: var(--body-font-size-veryTiny);

  }

  .blog-list li h4 {
    margin-bottom: var(--margin-tiny);
    font-size: var(--body-font-size-tiny);

  }
  .blog-list li {

    height: 150px;
    width: 150px;
   
    overflow: hidden;
  
  }
  .blog-list p{
    font-size: var(--body-font-size-tiny-tiny);
  }
  

  .blog-list li:hover p {
    font-size: var(--body-font-size-tiny);
  }

 

  .blog-list li:hover h4 {
    font-size: var(--body-font-size-tiny-tiny);
  
  }
  .blog-list li:hover {

    background: linear-gradient(to bottom, var(--accent-color-secondary), #C7E6D7);
    ;

  }
  .blog-list-header {
    width: 100%;
  }

  .circular-content {
    padding: 20px 10px;

  }

  .blog-decorator-top,
.blog-decorator-bottom {
  width: 100px;
  height: 100px;

  border-radius: 50%;
  position: absolute;



}

.blog-decorator-bottom-small {
  width: 50px;
  height: 50px;

  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: -20px;
  opacity: 1;
  background-color: var(--primary-color);
  z-index: 1;



}

.blog-decorator-top {
  top: -25px;
  left: -25px;
  opacity: 50%;
  background-color: var(--accent-color-secondary-strong);
}

.blog-decorator-bottom {
  bottom: -50px;
  right: -50px;
  opacity: 50%;
  /* background-color: var(--secondary-color); */
  z-index: -1;

}
.blog-display img{
  max-width: 80%;
  max-height: 80%;
 
}





}