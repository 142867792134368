.header {
 
  font-size: var(--body-font-size);
  padding: 0;
  display: flex;
  /* justify-content: center; */
  justify-content: center;
  align-items: center;
  gap: var(--margin-small);
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-small);
  
  

 
  
}
.Web-logo {
 
   cursor: pointer;
   width: 20%;
  
   display: flex;
   justify-content: first baseline;
   align-items: center;
  
  }
  .logo {
    width: 60px;
    height: 60px;
  }
  .logo:hover {
    scale: 1.2;
  }
  .lang-icon {
    width: 22px;
    height: 22px;
  }
  a {
    color: #474554;
    text-decoration: none;
  }
  
  
  .header-nav
 {
 
  /* background-color: #F5F5F5; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  /* justify-content: first baseline; */
 
 
 
  
 
 
    
  }
  .header-nav > div{
    flex-grow: 1;
  }
  .nav-links{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
   
    
  }




.active-link{
  border-radius: 5px;
  padding: 0 5px;
  color: #C7E6D7;
  background-color: var(--strong-black);
}

@media (width> 600px) and (width<800px) {
  .header { 
    font-size: var(--body-font-size-tiny);
    
  }
  .logo{
    width: 80px;
    height: 80px;
  }
}


.hamburger-menu {
display: none; /* Hide by default on larger screens */
cursor: pointer;
padding: var(--margin-tiny);
}
@media (width<= 650px) {
.header { 
  font-size: var(--body-font-size-tiny-tiny);
  
}
.logo{
  width: 80px;
  height: 80px;
}
}
@media (width>= 800px) and (width<900px){
.header{
  font-size: var(--body-font-size-small);
}
}
/* Media Query for Small Screens */
@media (width<= 600px) {
/* Show Hamburger Menu Icon */
.hamburger-menu {
  display: block;
  font-size: var(--header-h3-font-size);
  position: absolute;
  top: 2vh;
  right: 5vw;
  z-index: 100;
}
.hamburger-menu:hover {
  transform: scale(1.2);
  color: var(--accent-color-primary);
  background-color: var(--secondary-color);
  font-weight: bold;
} 


/* Hide Navigation Links by Default on Small Screens */
.nav-links {
  opacity: 0;
  visibility: hidden;
  /* transition: opacity 0.2s ease, visibility 0.2s ease; */
}


/* Show Navigation Links when the menu is open */
.nav-links.show {
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5%;
  right: 0;
  width: 100%;
  margin: auto;
  background-color: var(--accent-color-primary);
  color: var(--strong-black);
  gap: var(--margin-medium);
  font-size: var( --header-h4-font-size);
  border-radius: 0px 0px 20px 20px;
  opacity: 0.9;
  font-weight:500;
  padding: var(--margin-small);

}


/* Style for Navigation Links in the menu */
.nav-links.show a {
  padding: 15px;
  text-align: center;
  text-decoration: none;
  /* background-color: var(--primary-color); */
  color: var(--secondary-color);
}
.nav-links.show a:hover {
  background-color: var(--secondary-color);
  color: var(--accent-color-primary);
  border-radius: 5px 10px;
}

.header{
  font-size: var(--body-font-size-tiny);
 
 
}
}
