
.contacts-more {
  font-family: var(--body-font-family);
  
  line-height: 1.5; 
  text-align: center;
  margin: auto;
  position: absolute;
  width:30%;
  bottom: 10%;
  right: 0;

  
 
}
.contacts-more img{
  max-width: 200px;
  max-height: 200px;
  border-radius: var(--margin-large) var(--margin-small);
  
}


.contacts-quick-container {
  display: flex;
  flex-direction: column;
  gap: var(--margin-small);
  width: 50%;
  justify-content: center;
  border: 1px solid black;
  margin: auto;
  font-size: var(--body-font-size);
  padding: var(--margin-tiny) auto;
  border-radius: 50%;
  overflow: hidden;
  
}
.contact-item{
    font-size: var(--body-font-size-small);
}
.contact-item:hover{
  scale: 1.1;
  font-weight: bold;
 
  border: 1px solid #474554;
  animation: borderAnimation 2s infinite;
  
 
  

}
.contacts-quick-container:hover {
  animation: containerBorderAnimation 2s infinite;
}
@keyframes containerBorderAnimation {
  0%, 100% {
    border: 2px solid #aaffaa;
  }
  35%{
    border: 2px solid var(--secondary-color);
  } 
  70% {
    border: 2px solid #aaaaff;
  }
}

@keyframes borderAnimation {
  0%, 100% {
    border-radius: 8px 16px;
  }
  50% {
    border-radius: 16px 8px;
  }
}


.input-form {
 
  display: flex;
  flex-direction: column;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size-small);
  width: 40%;
  background-color: var(--accent-color-primary);
  text-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: var(--margin-small);
  height: 80vh;
}


.input-form h3 {
  font-size: 20px;
  font-family: var(--body-font-family);
  padding-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.input-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.input-wrapper {
  
  box-shadow: var(--box-shadow-orange);
  width: 100%;
  margin-bottom: var(--margin-small);
  

}
.input-group{
  position: relative;
  height: 40px;
  width: 100%;

}

/* input {
  
  all: initial;
} */

.input-group input[type="text"],
.input-group input[type="tel"]
{
  
  all: initial;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 1px solid gray;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  background-color: var(--primary-color);
}

.input-group label{
  position: absolute;
  left: 0;
  bottom: 10px;
  color: gray;
  pointer-events: none;
  transition: all 0.3s ease;
}


.input-underline{
  position: absolute;
  bottom: 0;
  left: 0;
 
  height: 2px;
  width: 100%;
  

 
}
.reach-you input[type="text"],
.reach-you input[type="tel"]{
  height: 100%;
  border-bottom: 2px solid #aaaaff;
 
}


.input-underline:before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--logo-font-color-accent);
  left: 0;
 
 
}

textarea {
  /* Reset all styles to default */
  all: initial;
}

.input-group textarea {
  width: 100%;
  padding:  10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 10px 10px 0px 0px;
  font-size: var(--body-font-size-small);
  font-family: var(--body-font-family);
  background-color: var(--primary-color);
  word-wrap: break-word;
  
}
.input-group textarea {
   height: 100%;
}


.cta-contact {
  
  font-family: var(--header-font-family);
  font-size: var(--header-h3-font-size);
  color: var(--secondary-color);
  background-color: var(--primary-color);
  width: 80%;
  text-align: center;
  height: 50px;
  border-radius: 5px 10px;
  transition: all 0.3s ease;
  }

  .cta-contact:hover {
    background-color: var(--secondary-color);
    color: var(--accent-color-primary);
   
    border-radius: 5px 10px;
    cursor: pointer;
    border: 2px solid var(--logo-font-color-accent);
    
    }

    @media (width>800px) and (width <= 1000px) {
      .input-form form{
        width: 100%;
      }
      .input-group{
        
        height: 30px;
        width: 100%;
      
      }
      
    }
    @media (width>430px)and(width<=800px) {
      .input-form {
        width: 80%;
        height: 100vh;
        
      }
      .input-form form{
        width: 80%;
      }
      

            
    }

    @media (width>380px) and (width<=430px) {
      .contacts-container{
        height: 135vh;
        margin-bottom: var(--margin-tiny);        
        
      }

      .contacts-more {
      
        line-height: 1.5; 
        text-align: center;
        margin: auto;
        position: absolute;
        width:80%;
        bottom: 0%;
        left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        gap: var(--margin-bento);
       
      }
   
      .input-form {
        width: 80%;
        margin-bottom: var(--margin-tiny);
        
      }
      .input-form form{
        width: 90%;
      }
     
      .input-form{
        height: 75vh;
      }
    
    
      .contacts-more img{
        width: 200px;
        height: 200px;
      }
      
      
      .contacts-quick-container {
        
       
        gap: var(--margin-small);
        width: 50%;
        justify-content: center;
      
        font-size: var(--body-font-size-small);
        padding: var(--margin-tiny) auto;
        border-radius: 50%;
       
       
       
        
      }
       .contacts-container h2{
         width: 90%;
       }
       .motto{
        width: 90%;
        margin: auto;
       }
       .motto h1{
         margin-bottom: var(--margin-small);
       }
      
    }

    @media (width<=380px){
      .contacts-container{
        height: 135vh;
        margin-bottom: var(--margin-tiny);
        
      }
      .input-form {
        width: 90%;
        height: 90vh;
       
      }
      .input-form form{
        width: 90%;
       
       
      }
     
    
      .contacts-more {
      
        line-height: 1.5; 
        text-align: center;
        margin: auto;
        position: absolute;
        width:80%;
        bottom: 0%;
        left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
        
      
        
       
      }
      .contacts-more img{
        width: 150px;
        height: 150px;
        border-radius: var(--margin-small) var(--margin-tiny);
      }
      
      
      .contacts-quick-container {
        
       
        gap: var(--margin-small);
        width: 50%;
        justify-content: center;
      
        font-size: var(--body-font-size-small);
        padding: var(--margin-tiny) auto;
        border-radius: 50%;
       
       
       
        
      }
      
       .motto{
        width: 90%;
        margin: auto;
       }
      .contacts-container h2{
        width: 90%;
      }
      .motto h1{
        margin-bottom: var(--margin-small);
      }
     
      
    }
   
    
