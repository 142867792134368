.footer-container{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    background-color:#bbb;
    color: var(--strong-black);
    font-family: var(--header-font-family);
    font-size: var(--body-font-size-small);
    text-align: left;
}

.footer-container >div{
    width: 90%;
    margin: auto;
}
.footer-container >div:nth-child(2){
    width: 100%;
    margin: auto;

}

.footer-navs {
    /* flex: 1; */
    display: flex;
    min-height: 40vh;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: var(--margin-medium);
    align-items: flex-start;
     
   
}

.column li{
    list-style: none;
    margin-bottom: var(--header-h4-font-size);    
    font-weight: 300px;
    font-family: var(--header-font-family);
}

.column .footer-link{
   
    color: var(--strong-black);
   
}
.column .social{
    text-align: center;
    transform: scale(1.3);
}
.column .footer-link:hover{
    cursor: pointer;
    transform: scale(1.5);
   
    
}
.column h2 {
    font-family: var(--header-font-family);
    font-size: var(--body-font-size-small);
    /* font-weight: 300px; */
    margin-bottom: var(--header-h4-font-size);
    text-decoration: underline;
   
}
.company-name-footer{
   
  color: var(--logo-font-color-primary);
  font-family: var( --logo-font-family);
  font-size: var(--header-h4-font-size);
  letter-spacing:5px;
}
.footer-motto{
    font-size: var(--body-font-size-tiny-tiny);
    font-family: var(--header-font-family);
   

    margin: auto;
   
    text-align: center;
  
    padding: var(--margin-tiny);
    color: var(--primary-color);
    background-color: var(--secondary-color);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--margin-medium);
    letter-spacing: 2px;

    
}
.footer-motto p{
    line-height: 1.5;
    text-shadow: var(--box-shadow-orange);
}
.footer-note {
   
    font-family: var(--header-font-family);
 
    border-top: 1px solid var(--accent-color-secondary);
    border-bottom: 2px solid var(--accent-color-secondary);
  
    text-align:center;
    font-weight: 100;
   
    font-size: var(--body-font-size-tiny);
    padding: var(--margin-tiny);
    
}
.footer-note img,
.footer-motto img{
    width: var(--logo-size);
    height: var(--logo-size);
    box-shadow: var(--box-shadow-orange);
    background-color: var(--accent-color-secondary);
  
}
.footer-note img,
.footer-note img{
    display: block;
}
.footer-container>div:last-child{
    height: var(--margin-medium);
}

@media (width>430px)and(width<=800px) {
    /* .footer-container{
        font-size: var(--body-font-size-tiny);
        
    } */
    /* .footer-navs {
        justify-content: space-between;
    } */
    .footer-container >div{
        width: 100%;
        margin-right: var(--margin-bento);
    }
    .footer-navs h2{
        font-size:var(--body-font-size-tiny);
    }
   
    
}
@media (width>430px) and (width<=750px){
    .footer-container >div{
        width: 60%;
        margin: auto;
    }
    
    .footer-navs {
        /* flex: 1; */
        display: flex;
        /* min-height: 50vh; */
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: var(--margin-medium);
        align-items: flex-start;
         
       
    }
    .column li{
        margin-bottom: 0px;
    }
    .column h2 {
        margin-bottom: var(--body-font-size-veryTiny);
        margin-top: var(--header-h4-font-size);
    }
    .footer-motto{
        flex-direction: column;
    }
  
}
@media (width<=430px){
    .footer-container >div{
        width: 60%;
        margin: auto;
    }
    
    .footer-navs {
        /* flex: 1; */
        display: flex;
        /* min-height: 50vh; */
        flex-direction: column;
        justify-content: space-evenly;
        padding-top: var(--margin-medium);
        align-items: flex-start;
         
       
    }
    .column li{
        margin-bottom: 0px;
    }
    .column h2 {
        margin-bottom: var(--body-font-size-veryTiny);
        margin-top: var(--header-h4-font-size);
    }
    .footer-note{
        font-size: var(--body-font-size-tiny);
    }
    .footer-motto{
        flex-direction: column;
        padding: var(--margin-small) var(--margin-bento);
        justify-content: center;
        align-items: center;
        font-size: var(--body-font-size-tiny);
        gap: var(--margin-tiny);
    }
    .footer-motto p{
        width: 75%;
        margin: auto;
        text-align: left;
    }
}