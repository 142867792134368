.LinkToPortfolios {
   
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color);
    color: var(--strong-white);
    font-family: var(--header-font-family);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: var(--header-h3-font-size);
     
  }

.LinkToPortfolios-english,
.LinkToPortfolios-amharic{
 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: 500;
 
 
}
.LinkToPortfolios-english div,
.LinkToPortfolios-amharic div {
    
  /* margin-top: var(--margin-small); */
}
.LinkToPortfolios-english div>ul>li,
.LinkToPortfolios-amharic div>ul>li{
    list-style: none;
    margin: var(--margin-small) auto;
    
}
.LinkToPortfolios-english div>ul>li:hover,
.LinkToPortfolios-amharic div>ul>li:hover{
  /* background-color: #C7E6D7; */
  /* color: #474554; */
  border: 2px solid #c7e6d7;
  cursor: pointer;
  animation: borderAnimation 2s infinite;
}
.LinkToPortfolios-english >div:first-of-type{
    margin-right: var(--margin-large);
    font-family: var(--body-font-family);
    font-weight: 400px;
   
}


@keyframes borderAnimation {
  0%, 100% {
    border-radius: 10px 20px;
  }
  50% {
    border-radius: 20px 10px;
  }
}

.infinite-animation {
  animation: borderAnimation 2s infinite;
}
.arrow-container > p{
  display: inline-block;
}
.arrow-container {
  flex: 1;
  flex-direction: row;
}

.arrow-icon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
  }
   
  .arrow-icon-container.animated {
    animation: borderAnimation 2s infinite;
  }
  
.arrow-icon-in-link {
  color: white;
  border: 2px solid white;
  padding: 5px 10px;
  border-radius: 5px 10px;
  
}

@media (width<=430px){
  .LinkToPortfolios{
    width: 80%;
    
    
    font-size: var(--body-font-size-small);
  }
  .arrow-container{
    font-size: var(--body-font-size);
  }
}
