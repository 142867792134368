.business-niche-section {
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80dvh;
    height: 80vh;
  
  
    
  }

  .cta-container {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
    border-radius: 8px 4px;
    position: relative;
    word-wrap: break-word;
    flex-wrap: wrap;
    
  }
  .cta-container h2{
    /* font-size: var(--header-h2-font-size); */
    font-size: var(--header-h1-small-font-size);
    line-height:1.5;
  
  }
 
  .niche-container {
    
    width: 55%;
    height: 100%;
   
    display: flex;
     flex-direction: column;
   justify-content: center;
    align-items: center;
    gap: var(--margin-medium); 
    position: relative;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 8px 4px; 
    margin-bottom: 0;
    
  }

  .cta-button-home {
    all: initial;
    color: var(--strong-black);
    font-family: var(--header-font-family);
    font-size: var(--header-h3-font-size);
    font-weight: bold;
    background-color: var(--accent-color-primary);
    box-shadow: var( --box-shadow);
    width: 300px;
    height: 50px;
    border-radius: 10px 20px;    
    text-align: center;   
    display: block;
    
    /* transition: all 0.5s ease; */
    
   
    }
  

  .niche-item {
    /* font-size: 5rem; */
    margin: 0;
    padding: 0;
    font-weight: bold;
    opacity: 0;
    /* position: absolute; */
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    /* left: 50%; */
    transform: translate(-50%, -50%) translateY(0);
    font-size: 5rem;
    left: 50%;
    top:50%;
    padding: 32px,
             
  }
  
  .niche-item.active {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
  
  .niche-item.leaving {
    transform: translate(-50%, -50%) translateY(50%); /* Adjust the height for dynamic movement */
  }

  
  .landing-allday{
 
    position: absolute;
    top: 5%;
    left: 10%;
    
    color:var(--primary-color);
    font-size: var(--header-h4-font-size);
    font-family: var(--body-font-family);
    text-transform: uppercase;
    text-shadow: var(--box-shadow-orange);
    width: 75%;
    line-height: 1.5;
  
   
  }
  .landing-footnote {
   
    text-align: left;
    /* width: 40%; */
    /* text-transform: capitalize; */
    
  }

  @media (width > 950px) and (width<=1150px) {
    .niche-item{
      font-size: 4.5rem;
    }
    
  }
  @media (width>900px) and (width<=950px){
    .business-niche-section {
    
      gap: var(--margin-small);
    }
    .niche-item{
      font-size: 4rem;
      position: absolute;
      left: 50%;
      top:50%;
      /* padding: 32px, */
    }
    .cta-container{
    
      padding: 5px;
      justify-content: center;
      width: 30%;
    }
    .cta-container h2{
      font-size: var(--header-h3-font-size);
      line-height: 1.2;
    }
   .cta-button-home{
    width: 75%;
   }
   .landing-allday{
    font-size: var(--body-font-size);
   }
 
  }

  @media (width > 800px) and (width<=900px){
    .business-niche-section {
    
      gap: var(--margin-small);
    }
    .niche-container {
      width: 50%;
     

    }
    .niche-item{
      font-size: 3rem;
      position: absolute;
      left: 45%;
      top:50%;
      /* width: 50%; */
    }
    .cta-container{
     
      padding: 5px;
      justify-content: center;
      width: 30%;
      margin-left: var(--margin-large);

    }
    .cta-container h2{
      font-size: var(--header-h3-font-size);
      line-height: 1.2;
    }
   .cta-button-home{
    width: 75%;
   }
   .landing-allday{
    font-size: var(--body-font-size-small);
   }

  }

  @media (width>430px) and (width<= 800px) {
    .business-niche-section {
      flex-direction: column;
      height: 140vh;
      gap: var(--margin-medium);
      
    }
   

    .cta-container h2{
      font-size: var(--header-h2-font-size);
      width: 80%;
    }
    .cta-container{
      height: 50%;
     
      justify-content:flex-start;
      padding: 5px;    
      width: 100%;
      order: 1;

     
    }
    .niche-container{
      height: 50%;
      width: 100%;
      order: 0;
    }
    .niche-item{
      font-size: 4rem;
    }
    .landing-allday{
      font-size: var(--body-font-size);
     }
   
    
  }

  .fill-container {
    position: relative;
    /* border-radius: 50%; */
    overflow: hidden;
    height: 100%;
    width: 100%;
    /* box-shadow: var(--box-shadow); */
    box-shadow:  4px 4px 12px rgba(170,170, 255, 0.8);
    border: 1px solid rgba(170,170, 255, 0.8);
    
  }
  
  @media (width>380px) and (width<=430px) {
    .business-niche-section {
      flex-direction: column;
      height: 90dvh;
      height: 90vh;
      
      justify-content: flex-start;
      align-items: center;
      margin-top: var(--margin-small);
      gap: 2vh;
     
      
    }
    .niche-container{
      height: 50%;
      width: 100%;
      order: 0;
     
      
    }
   

    .cta-container h2{
      font-size: var(--header-h4-font-size);
      width: 70%;
      
      margin: var(--margin-small) auto auto auto;
      margin-bottom: 0;
    }
    .cta-container{
      height: 40%;
      display: flex;
      flex-direction: column;
     
      justify-content:space-around;
      /* align-items: flex-start; */
      padding: 5px;    
      width: 90vw;
      order: 1;
     
      gap:0;
      
      
      
     
    }
  
    .cta-container button{
      display: block;
    }
   
    .niche-item{
      font-size: 2.5rem;
     
      margin: auto;
   
      text-align: center;
      
    }
    .landing-allday{
      font-size: var(--body-font-size-tiny-tiny);      
     }
     .cta-button-home {
     
      font-size: var(--body-font-size);
      
      width: 50vw;
      height: 50px;
      
     
      }
     
    
  }
  @media (width<=380px) {
    .business-niche-section {
      flex-direction: column;
      height: 100dvh;
      height: 100vh;
      
      justify-content: flex-start;
      align-items: center;
      margin-top: var(--margin-small);
      gap: 0;
     
      
    }
    .niche-container{
      height: 45%;
      width: 100%;
      order: 0;
     
      
    }
   

    .cta-container h2{
      font-size: var(--header-h4-font-size);
      width: 90%;
      
      margin: var(--margin-tiny) auto auto auto;
      margin-bottom: 0;
    }
    .cta-container{
      height: 40%;
      display: flex;
      flex-direction: column;
     
      justify-content:space-around;
      /* align-items: flex-start; */
      padding: 5px;    
      width: 90vw;
      order: 1;
     
      gap:0;
     
      
      
     
    }
  
    .cta-container button{
      display: block;
    }
   
    .niche-item{
      font-size: 2.5rem;
     
      margin: auto;
   
      text-align: center;
      
    }
    .landing-allday{
      font-size: var(--body-font-size-tiny-tiny);      
     }
     .cta-button-home {
     
      font-size: var(--body-font-size);
      
      width: 50vw;
      height: 50px;
      
     
      }
     
    
  }
