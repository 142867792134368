.language {
    
    margin-left: var(--margin-veryLarge);
  }
  
  .language a {
    text-decoration: none; /* Remove default underline on anchor tags */
  }
  
  .lang-icon {
    margin-bottom: 2px; /* Adjust the margin on the image */
  }
  .lang-icon:hover{
    scale: 1.5;
    opacity: 1;
}
.language img{
  opacity: 0.75;
}
@media (width<=600px) {
  .language {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    justify-content:center;
    align-items: center;
  }
  .language>a>img{
    position: relative;
    top: 5px;
  }
  .language>a>img,
  .language>a>p{
    display: inline-block;
   
  }
  
  .language>a>p{
    margin-left: var(--margin-tiny);
   
  }
  
}
  