
.motto-about {
  background-color: var(  --logo-background-color);
  color: var(--primary-color);
  position: relative;
  padding: var(--margin-medium);
  width: 80%;
  display: flex;
  gap: var(--margin-small);
  justify-content: center;
  align-items: center;

  
}
.promo-img-container{
  width: 250px;
  height: 250px;
  overflow: hidden;

}
.promo-img-container img{
  /* position:absolute;
  top: 0;
  right:0; */
  max-width: 100%; 
  max-height: 100%; 
  display: block;
  box-shadow: var(--box-shadow);
  object-fit: cover;
  background-color:var( --accent-color-primary);

}
.motto-about h1{
  font-family: var(--header-font-family);
  font-size: var(--header-main-font-size);
  width: 65%;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: var(--margin-medium);
  

}
.about-company {
  width: 80%;
  
  text-align: left;
  /* margin: var(--margin-medium) auto; */
  margin: auto;
  /* background-color: #f5f5e0; */
}
.about-reNiche {
  width: 72%;
  margin: auto;
  padding: 10px;
  color: #f5f5f5;
  background-color: #474554;
  text-align: center;
}

.about-company p{
  font-family: var(--body-font-family);
  text-align: left;
  line-height: 2rem;
 
 
  margin-bottom: var(--margin-tiny);
  /* color: var(--primary-color); */
  /* background-color: #4D3531; */
}
.personal-picture {
  
  margin: 0 auto;
  filter: sepia(100%) hue-rotate(30deg);
}

.personal-picture  img ,
.logo-picture img{
  width: 200px; 
  height: 200px; 
  object-fit: cover;
  border-radius: 50%;
}
.about-sellingpoints {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: #efefef;
  padding: var(--margin-small);
  
 
}
.team-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--margin-small);
  flex-wrap: wrap;
  height: 70vh;
  padding: var(--margin-tiny);
}
.member-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--margin-tiny);
  flex-wrap: wrap;
  height: 100%;
  
  
}
.teamPic{
  display: inline-block;
  max-width:100%; 
  max-height: 100%;
  object-fit: contain;
  border-radius: 10%;

}

.member-info p {
  font-family: var(--header-font-family);
}

.sellingpoints-container {
 
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: var(--margin-medium);
   height: 70vh;
  width: 60%;
 
  justify-content: center;
  align-items: center;
  padding: var(--margin-tiny);
 
}

.sellingpoints-title {
  display: flex;
  /* background: linear-gradient(to bottom, var(--accent-color-secondary),var(--accent-color-primary)); */
  /* background-color:var(--card-background-color); */
  justify-content: center;
  align-items: center;
  
  text-align: center;
  font-weight: bold;
  overflow: hidden; /* Hide overflowing content */
  border-radius: 10%;
  box-shadow: var(--box-shadow);
  width: 60%;
  margin: auto;
}
.iconImage{
  display: none;
  width: 90%;
  height: 90%;
  /* display: block; */
  /* background-color: var(--accent-color-primary); */
  object-fit: cover;
  border-radius: 10% 20%;
 
}
.intro-text-img img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.sellingpoints-container{
  width: 90%;
}


.sellingpoints-container ul{
  display: block;
  list-style: none;
}
.sellingpoints-container ul>li{
  
  font-size: var(--header-h3-font-size);
  margin-bottom: var(--margin-small);
  text-align: left;
 
}






.sellingpoints-title{

 font-size: var(--header-h3-font-size);
 justify-content: center;
 height: 50%;
 border-radius: 40px 20px;

}



.logo-picture p{
  margin: auto 0;
  font-size:  var( --header-h1-font-size);
  /* position: relative;
  left: -20%; */
}
img.logo-motion{
  width: 200px; 
  height: 200px; 
  object-fit: cover;
  border-radius: 50%;
}
.cfa-logo-container{
  position:absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  /* box-shadow: var(--box-shadow); */
}
.cfa-logo-container img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company-name{
  /* color: var(--logo-font-color-primary); */
 
  text-transform: lowercase;
  
 
}
.motto-about .company-name{
  font-size: 96px;
  font-family: var( --logo-font-family);
  text-transform: lowercase;
}

.text {
  position: absolute;
  color: var(--strong-white);
  font-size: var(--body-font-size);
}

.left-to-right {
  animation: leftToRight 5s linear infinite;
}

.top-to-bottom {
  animation: topToBottom 5s linear infinite;
}

.right-to-left {
  animation: rightToLeft 5s linear infinite;
}
.medium-bubble-intro{
  animation: pulse-medium 2s infinite alternate;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.sellingpoints-title:nth-child(even) {
  color: var(--primary-color);
}

.sellingpoints-title:nth-child(odd) {
  color: var(--secondary-color);
}
.intro-skills{
  width:90%
}

@keyframes leftToRight {
  0% { left: -100%; }
  100% { left: 100%; }
}

@keyframes topToBottom {
  0% { top: -100%; }
  100% { top: 100%; }
}

@keyframes rightToLeft {
  0% { right: -100%; }
  100% { right: 100%; }
}
@keyframes moveReflectiveLight {
  to {
    left: 100%; 
  }
}
@keyframes moveReflectiveLightRight {
  to {
    right: 100%; 
  }
}

/* Apply the animation to each div within the .selected-quotes */
.highlight-turbo {
  /* padding: auto 40px; */
  /* margin-left: var(--margin-medium); */
  display: inline-block;
  text-transform: uppercase;
  animation: highlight-turbo-animation 4s linear infinite; /* Adjust the duration (4s in this example) */
}

/* Add this CSS to define the animation */
@keyframes highlight-turbo-animation {
  0%{
    transform: scale(1);
  }
  25% {    
    color: var(--secondary-color);  
    transform: scale(1.5);
    
  }

  50% {
    
    scale: 1.5;
   
    color:var(--logo-font-color-primary);
    background-color: none;
    transform: translateX(0);
  }
 
  69%{

    background:var(--primary-color);
    box-shadow: none;
  }
  70%{
   
   scale: 3;
   transform: translateX(200px);
   /* background-color: var(--accent-color-primary); */
   background-color: var(--logo-font-color-primary);
   /* box-shadow: var(--box-shadow); */
   box-shadow: 0 0 10px #ffff00;
    
  }
  
  71% {
    background:var(--primary-color);
    box-shadow: none;

  }
  100% {
    background-color: none;
    
  }
}
.turbo-impact {
  position: relative;
  width: 80%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  box-shadow: var(--box-shadow);
  margin: 24px auto;
  animation: turbo-impact-animation 4s linear infinite;
  perspective: 800px;
  transform-style: preserve-3d;
}

.turbo-impact img{
  max-width: 250px;
  max-height: 250px;
}
.turbo-impact img,
.turbo-impact p,
.turbo-impact div {
  transform: translateZ(0); /* Workaround for backface-visibility in Safari */
}

.turbo-impact img{
  display: block;
}
.turbo-impact div{
  display: none;
}
.turbo-impact:hover,
.turbo-impact:hover .highlight-turbo {
  animation-play-state: paused;
}
.turbo-impact:hover img,
.turbo-impact:hover p{
  transform: rotateY(90deg); 
  opacity: 0; 
}
.turbo-impact:hover div
{
  transform: rotateY(0deg); /* Bring the div into view */
  opacity: 1; /* Show the div during the transition */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10%;
  height: 80%;
  width: 90%;
  background: #ffaaaa;
  transition: transform 0.5s ease, opacity 0.5s ease; /* Add a transition effect */

}
@keyframes turbo-impact-animation{
  69%{

    background:var(--primary-color);
    box-shadow: var(--box-shadow);
    transform: none;
  }
  70%{
   
  
   /* background-color: yellow; */
   box-shadow: 0 0 10px #ffff00;
   transform: rotateX(5deg) rotateY(10deg);
    
  }
  
  80% {
    background:var(--primary-color);
    box-shadow: var(--box-shadow);
    transform: none;

  }

}

.cfa-turbo{
    
  font-family: var( --logo-font-family);
font-size: var(--header-h1-font-size);
font-weight: bold;
width: 100%;
/* background: linear-gradient(to right, var(--accent-color-secondary),var(--accent-color-primary)); */


padding: 10px 10px;
/* margin: var(--margin-medium) auto var(--margin-small) auto; */

}
.business-card-section{
  
    width: 70%;
    display: flex;
    gap: var(--margin-small);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: var(--margin-tiny);
    margin: var(--margin-tiny) auto;
    
  
}
.business-card-container{
  width: 320px;
  height: 190px;  
  border: 2px solid var(--secondary-color); 
  overflow: hidden;
  padding: 2px;
  background-color: var(--secondary-color);
}
.business-card-container img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  
  
}


.download-icon{
  max-width: 90%;
max-height: 90%;
display: inline-block;   
opacity: 0.8; 
object-fit: contain;
}
@media (width<=430px) {


  .motto-about{
 
    width: 90%;
    margin: auto;
   
    position: relative;
    display: flex;
    justify-content: first baseline;
    
    align-items:first baseline;
    padding: var(--margin-small);
    gap: var(--margin-bento);
  }

  .promo-img-container{
    position: absolute;    
    top: 1%;
    right:1%;
    width: 120px;
    height: 120px;
    overflow: hidden;
  
  }
  .promo-img-container img{
    /* position:absolute;
    top: 0;
    right:0; */
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    box-shadow: var(--box-shadow);
    object-fit: cover;
    background-color:var( --accent-color-primary);
  
  }
    /* .motto-about img{
    
    position: absolute;    
    top: 1%;
    right:1%;
    width: 120px;
    height: 120px;
   
  
  } */
  
  .motto-about h1{
  
    font-size: var(--header-h3-font-size);
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  
    text-align: left;
    z-index: 98;
    
    
  
  }
  .motto-about h1 span{
    font-size: var( --header-h2-font-size);
   
  }
  .about-company {
    width: 90%;
   
    text-align: left;
    margin: auto;
  }
  .about-company p{
   
    text-align: left;
    line-height: 1.5rem;   
   
    width: 90%;
    margin-bottom: var(--margin-tiny);
   
  }
  .about-reNiche{
    width: 100%;
    margin: auto;
    font-size: var(--body-font-size-small);
    padding: var(--margin-bento);
  }
  .business-card-section{
    width: 100%;
    margin: auto;
    flex-direction: column;
    gap: var(--margin-tiny);
  }
  .business-card-container{
    width: 80%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 320px;
  max-height: 190px;
   
  }
  .business-card-container img {
   max-height: 80%;
    object-fit: contain;
    border-radius: 10px 20px;
    

  }
  .about-sellingpoints{
    margin-top: var(--margin-small);
  }
  .about-sellingpoints h2 {
    width: 90%;
  }
  .about-sellingpoints h2 span{
    font-size: var( --header-h1-font-size);
  }
  .sellingpoints-container{
   
    width: 80%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
   
    height: 50vh;
  }
 
  .sellingpoints-container ul>li{
    
    font-size: var(--body-font-size);
    margin-bottom: var(--margin-tiny);
   
  }
  

   .sellingpoints-title {
    
    border-radius: 5%;    
    width: 90%;
    margin: auto;
    height: 30%;
    font-size: var(--header-h4-font-size);
    /* display: flex;
    text-align: center;
    justify-content: center; */
    
    
  }
  .sellingpoints-title p {
   
    width: 80%;
    margin: auto;
   

  }
  
  .medium-bubble-intro{
   
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .intro-text-img{
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
   
  }
  .intro-text-img img{
    height: 100%;
    width: 100%;
    object-fit:contain;
  }
  
  
  .intro-text img{
    height: 50px;
    width: 50px;
    
    object-fit:fill;
  }
  
  
   
}
@media (width<=430px) {
  
  .cfa-logo-container{
   
    width: 75px;
    height: 50px;
   
  }
  .team-container{
   
    gap: var(--margin-tiny);
    height: 80vh;
    
  }
  
  .teamPic{
    
    max-width:90%; 
    max-height: 90%;
    margin: auto;
  
  }
  .turbo-impact {
    
    width: 100%;
    height: 50vh;
   
   
  }
  .turbo-impact img{
    max-width: 150px;
    max-height: 150px;
  }
  @keyframes highlight-turbo-animation {
    0%{
      transform: scale(1);
    }
    25% {    
      color: var(--secondary-color);  
      transform: scale(1.5);
      
    }
  
    50% {
      
      scale: 1.5;
     
      color:var(--logo-font-color-primary);
      background-color: none;
      transform: translateX(0);
    }
   
    69%{
  
      background:var(--primary-color);
      box-shadow: none;
    }
    70%{
     
     scale: 3;
     transform: translateX(120px);
     background-color: var(--logo-font-color-primary);
     box-shadow: 0 0 10px #ffff00;
      
    }
    
    71% {
      background:var(--primary-color);
      box-shadow: none;
  
    }
    100% {
      background-color: none;
      
    }
  }
  
 

}