
.tech-main-container{
  height: 90vh;
}
.tech-grid-container{
  width: 90%;
  grid-template-columns: 0.15fr 10fr 0.15fr;
  gap: 10px;
  padding: 20px 10px;
  
}
  .tech-testimony-container {
    
    background-color: #474545;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px 0 8px 0;
  
    height: 80%;
    padding-top: var(--margin-medium);
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 250px;
    
    }
    
 
    .tech-quote,
    .tech-author {
    
      border-radius: 4px 2px;
      justify-content: center;
      align-items: center;
      text-align: center;
       white-space: normal; 
      
      
    }
   
    
    .tech-testimony-container:hover {
      transform: scale(1.05);
      background-color: var(--accent-color-primary);
      border-color: var(--secondary-color); 
      cursor: pointer;
      
    }
    .tech-quote {
          
      background-color: var(--card-background-color);
      padding: 15px;
      font-size: var(--body-font-size-small);
      margin-bottom: var(--margin-tiny);
   
  }
    
     .tech-author {
      background-color: var(--secondary-color-light);  
      padding: 10px;
      color: #f5f5f5;
      font-size: var(--body-font-size-tiny-tiny);
      z-index: 15;
    }
    .slider-button {
        background-color: var(--accent-color-primary);
        height: 40px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
   
        border-radius: 10px 5px;
        border: var(--secondary-color);
        border: 1px solid var(--secondary-color);
    }
    .slider-button:hover{
        border: 2px solid var(--secondary-color);
        cursor: pointer;
    }
    /* Assuming this styling is applied to an element with the 'className' class */



    div::-webkit-scrollbar{
        display: none;
    }


    @media (width>380px) and (width<=430px){

    
      .tech-main-container{
        height: 60vh;
      }
      .tech-testimony-container{
        width: 200px;
        height: 200px;
      }

      .tech-quote {
          
        background-color: var(--card-background-color);
        padding: 15px;
        font-size: var(--body-font-size-tiny-tiny);
        margin-bottom: var(--margin-tiny);
     
    }
      
       .tech-author {
        background-color: var(--secondary-color-light);  
        padding: 10px;
        color: #f5f5f5;
        font-size: var(--body-font-size-veryTiny);
        z-index: 15;
      }
      .slider-button {
          background-color: var(--accent-color-primary);
          height: 40px;
      width: 30px;
     
     
     
          border-radius: 5px 2px;
        
      }
      .tech-grid-container{
        width: 95%;
        /* margin-left: 25%; */
        grid-template-columns: 0.5fr 5fr 0.5fr;
        gap: 2px;
        padding: 8px 4px;
     
        
      }
      .tech-main-container h2{
       
        width: 80%;
      
      
      
      }

    }

    @media (width<=380px){

    
      .tech-main-container{
        height: 80vh;
      }
      .tech-testimony-container{
        width: 185px;
        height: 200px;
      }

      .tech-quote {
          
        background-color: var(--card-background-color);
        padding: 15px;
        font-size: var(--body-font-size-tiny-tiny);
        margin-bottom: var(--margin-tiny);
     
    }
      
       .tech-author {
        background-color: var(--secondary-color-light);  
        padding: 10px;
        color: #f5f5f5;
        font-size: var(--body-font-size-veryTiny);
        z-index: 15;
      }
      .slider-button {
          background-color: var(--accent-color-primary);
          height: 40px;
      width: 30px;
     
     
          border-radius: 5px 2px;
        
      }
      .tech-grid-container{
        width: 95%;
       
        grid-template-columns: 0.5fr 5fr 0.5fr;
        gap: 2px;
        padding: 8px 4px;
        padding-bottom: 20px;
       
        
      }
      .tech-main-container h2{
       
        width: 80%;
      
      
      
      
      }

    }
    
  
 