.pricing {
   
    text-align: center;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    display: flex;
    flex-direction: column;
   
  }
  .pricing h2{
    font-family: var(--body-font-family);
    font-size: var(--header-h2-font-size);
  }
  .service-options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      text-align: left;
      border: solid 2px #FFD8B2;
      box-sizing: var(--box-shadow);
      margin-top: var(--margin-small);
      margin-bottom: var(--margin-large);
      padding-bottom: var(--margin-small);
      font-size: var(--body-font-size-small);
      
    }
  
    .service-options>div {
      margin: var(--margin-tiny);
    }
  
    .service-options>div:first-child{
      display: flex;
      width: 50%;
     
    }
    .service-options>div:first-child>label{
      margin-right: var(--margin-large);
    }
    .service-table {
      display: grid;
      grid-template-columns: 1.5fr 1.2fr 1.5fr;
      gap: var(--margin-medium);
      justify-content: center;
      align-items: left;     
  
    }
    .checkbox-options {
      justify-content: center;
      align-items: center;
      line-height: 16px; 
    }
    /* Style the checkbox container */
  .checkbox-options input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white; 
    border: 2px solid #474554; 
    border-radius: 4px 2px; 
    width: 24px; 
    height: 24px; 
    margin: 10px 10px auto auto;
   
  }
  
  /* Style the checked state of the checkbox */
  .checkbox-options input[type="checkbox"]:checked {
    background-color: var(--accent-color-primary);
   
  }
  
  /* Style the tick mark (pseudo-element) */
  .checkbox-options input[type="checkbox"]:checked::before {
    content: '\2713'; /* Unicode character for a checkmark */
    display: block;
    text-align: center;
    line-height: 24px; 
    color: green; /* Set the color of the checkmark */
  }
  
  
  
  .price-item {
    margin: auto var(--margin-medium);
  }
  
  .price-viewer {
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 300px;
    border:2px solid var(--accent-color-secondary);
    width: 90%;
  
  }
  
  .price-table {
    width: 100%;
    margin: 2px; 
    font-size: var(--body-font-size-tiny);
  } 
  
  .price-table th, .price-table td {
    padding: 2px;
    border: 1px solid #ddd;
    text-align: left;
   }
  
  .price-table th {
    background-color: #ffeede;
  }
  .total-price {
    
    display: flex;
    flex-direction: column;
    font-weight: bold;
    border: 2px solid var(--accent-color-secondary);
    border-radius: 10px 5px;
    margin-top: var(--margin-tiny);
    width: 90%;   
  
  }
  
  .radio-options {
    display: flex;
    gap: 5px;
    font-family: var(--header-font-family);
    font-size: var(--body-font-size);
    flex-wrap: wrap;
}

.radio-options input[type="radio"] {
    display: none; 
}


.radio-options label {
    display: inline-block;
    padding: 8px 16px;
    border: 2px solid #ccc;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    cursor: pointer;
    margin-right: var(--margin-tiny);
}

.radio-options label.radio-checked {
    background-color: var(--accent-color-primary); /* Change this to your desired color */
    font-weight: bold;
    
}

.radio-options label:hover {
    background-color: var(--accent-color-primary);  /* Change this to your desired hover color */
}

.select-services {
    display: flex;
    flex-direction: column;
    font-family: var(--body-font-family);
   
    
}


.service-table{
  font-family: var(--body-font-family);
}

.add-ons {
 
  font-size: var(--body-font-size-small);
  box-shadow: var(--box-shadow);
}
.add-ons h3{
  font-size: 16px;
}
.pricing-note {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size-tiny);
  width: 80%;
  padding: 10px;
}

.features-list{
  margin-right: 10px;
  border-bottom: 1px solid #474554;
  /* text-decoration: underline; */
}

.features-container{
  border: 2px solid #FFD8B2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-size: 12px;
}
.features-header {
  margin: 10px auto 10px 0;
  font-size: 16px;
  width: 70%;
  padding: 5px 0;
  text-decoration: underline;
  text-underline-offset: 5px;
}
.pricing-promo-container{
  width: 40%;
}

@media (width>430px) and (width<=650px) {
  .pricing-promo-container{
    width: 90%;
   
    
  
  }
  .service-selection {
    display: flex;
    justify-content: center;
    width: 70%;
   
    margin: auto;
  }
  .pricing h2{
    width: 90%;
    
  }
  .service-options>div:first-child{
   
   
    width: 90%;
    gap: var(--margin-small);
  
   
   
  }
  .service-options>div:first-child>label{
    width: 40%;
   
    font-size: var(--body-font-size-small);
   margin-right: 0;
  }
  .radio-options {
    width: 70%;
    font-size: var(--body-font-size-small);
    flex-wrap: wrap;
    justify-content: space-between;
   
    margin: auto;
  }
  .selection-info{
    width: 60%;
    margin-left: 50%;
   
    border: 2px solid red;
  }
  
  .radio-options label {
     
    
      box-shadow: var(--box-shadow);
      margin-right: var(--margin-tiny);
  }
  .service-table {
    display: flex;
    flex-direction: column;
    gap: var(--margin-medium);
    justify-content: center;
    align-items: center;     
  

  }
  .service-details-container{
    width: 70%;
    
  }
  .checkbox-options {
    line-height: 12px; 
    word-wrap: break-word;
  }
  .service-addons{
    width: 60%;
   
  }
  .pricing-container{
    width: 60%;
  }
  .price-viewer {
    padding: 10px;
    min-height: 120px;
    
    width: 100%;
      
  
  }
  .total-price {
    
  
    /* margin-top: var(--margin-tiny); */
    width: 50%;   
    margin: var(--margin-tiny) auto;
  
  }
  .pricing-note {
  
    width: 60%;
    padding: 4px;
    margin: var(--margin-tiny) auto;
  }
  


  
}
@media (width<=430px) {
  .pricing-promo-container{
    width: 90%;
   
    margin: var(--margin-small) auto auto auto;
  
  }
  .pricing h2{
    width: 90%;
    margin: var(--margin-small) auto auto auto;
  }
  .service-options>div:first-child{
   
   
    width: 90%;
    gap: var(--margin-small);
  
   
   
  }
  .service-options>div:first-child>label{
    width: 40%;
   
    font-size: var(--body-font-size-small);
   margin-right: 0;
  }
  .radio-options {
    
    font-size: var(--body-font-size-small);
    flex-wrap: wrap;
    justify-content: left;
  }
  
  .radio-options label {
     
      padding: 2px 4px;
      box-shadow: var(--box-shadow);
      margin-right: var(--margin-tiny);
  }
  .service-table {
    display: flex;
    flex-direction: column;
    gap: var(--margin-small);
    justify-content: center;
    align-items: center;     
  

  }
  .service-details-container{
    width: 100%;
    
  }
  .checkbox-options {
    line-height: 12px; 
    word-wrap: break-word;
  }
  .service-addons{
    width: 100%;
   
  }
  .pricing-container{
    width: 100%;
  }
  .price-viewer {
    padding: 10px;
    min-height: 120px;
    
    width: 100%;
      
  
  }
  .total-price {
    
  
    /* margin-top: var(--margin-tiny); */
    width: 80%;   
    margin: var(--margin-tiny) auto;
  
  }
  .pricing-note {
    font-size: var(--body-font-size-tiny-tiny);
    width: 90%;
    padding: 4px;
    margin: var(--margin-tiny) auto;
  }
  


  
}