.animated-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:80vh;
    width: 90%;
    margin: var(--margin-medium) auto;
    
    position: relative;
   
       
  }
  .animated-card-container>div:first-child{
    width: 100%;
  }
  .solutions-header {
    font-family: 'Montserrat Alternates', sans-serif;
  font-size: var(--header-h1-font-size);
  font-weight: bold;
  width: 100%;
  background: linear-gradient(to right, var(--accent-color-secondary),var(--accent-color-primary));
  width: 50%;
  border-radius: 20px 10px;
  padding: 20px 10px;
  }
 
  
  
  .card {
    display: grid; /* Use grid for a two-column layout */
    grid-template-columns: 250px 600px; /* 150px for the left side, 1fr for the right side */
    border-radius: 10px 0px 10px 10px;
    box-shadow: var(--box-shadow-light);
    overflow: hidden;    
    margin-left: var(--margin-tiny);
    text-align: center;    
    justify-content: center;
    align-items: center;   
    padding-left: var(--margin-small);
    gap: var(--margin-large);
    position: relative;
    width: 100%;
    height: 100%;
    /* padding-left: 80px; */
  }
  .card-title {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    background-color: var(--accent-color-secondary);
    color: var(--text-color);
    font-weight: bold;
    text-align: center;
  
    
   
    /* border-radius: 50%; */
  }
  
 .card>div:first-child{
  height: 75%;
  justify-content: center;
 }
  
  .card-content {
    position: relative;
    display: flex;
    /* align-items: flex-start;  */
    justify-content: space-between;
    flex-direction: column;
    padding: var(--margin-small);
    
    font-family: var(--body-font-family);  
  
    width: 90%;
    height: 80%;
    text-align: left;
    /* border: 2px solid red; */
   
    
    
  }
  
  .card-subtitle {
    position:absolute;
    top: 10%;    
    left: 0;
    text-align: left;    
    font-size: var(--header-h3-font-size);
    font-weight: bold;   
    
    min-height: 25%;
   
    
    /* text-transform: uppercase; */
  }
  .card-body {
    position: absolute;
    top: 40%;
    left: 0;
    font-size: var(--body-font-size);
 
    width: 100%;
  }
  
  .card-foot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: var(--body-font-size-tiny);
    font-weight: bold;
  }
  
 .card-body ul{
  margin-bottom: var(--margin-tiny);
  list-style: none;
 }
 .card-body ul li{
   margin-bottom: var(--margin-tiny);
   display: block;
   justify-content: center;
 }

 .card-body ul li .custom-marker{
  position: relative;
  display: inline-block;
  margin-left: var(--margin-large);
 
}
.card-body ul li .custom-marker::before {
  content: '\25A0';   
  /* content: '\26A1';  */
  /* content: '\2713'; */
 
  font-size: 2em;       
  position: absolute;
  top: -0.5em;
  left: -1.2em;
  text-shadow: var(--box-shadow-light);
 
}
.progress-circle{
  width: 15%;
}

/* Color classes */
.card-body ul li .custom-marker.yellow::before {
  color:#FFD700;

}
.card-body ul li .custom-marker.green::before {
  color:#aaffaa;

}
.card-body ul li .custom-marker.cream::before {
  color:#ffffaa;

}
 
.card-body ul li .custom-marker.magenta::before {
  color:#ffaaaa;

}
.card-body ul li .custom-marker.blue::before {
  color:#7ec8e3;

}
.progress-circle >div{
  width: 25px;
  height: 25px;
}
.mouse-over-circle{
  opacity: 1;

 }


@media (width>800px) and (width<=950px) {
  .card {
    grid-template-columns: 250px 450px ;
  }
  
}

@media (width > 700px) and (width<=800px) {
  .card {
    display: grid;
    grid-template-columns: 250px 350px ;
  }
}
@media (width>430px)and(width<=700px){


  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    width: 100%;
    gap: var(--margin-medium);
    border: 2px solid red;
   

    }
  .card-subtitle {
    font-size: var(--header-h4-font-size);
    /* top: 10;  */
    
  }
  .card-body{
    top:50%;
    font-size: var(--body-font-size-small);
  }
  .card-body ul li{
    margin-bottom: var(--margin-veryTiny);
  }
  .progress-circle{
    border: 2px solid red;
  }
 
  .progress-circle >div{
    
   width: 15px;
   height: 15px;
   border: 2px solid red;
   
  }
  



}

@media (width>380px) and (width<=430px){
 
  .animated-card-section h2{
    width: 90%;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    width: 100%;
    gap: 0;
   

    }
    .card>div:first-child{
    
      min-height: 50%;
      width: 90%;
      margin-right: 10%;

    }
    .card-content{
     
      width: 100%;
      margin: auto;
    }
    .card-subtitle{
      font-size: var(--body-font-size);
    }
  
 
 

  .progress-circle{

    width: 50%;
  }
  .progress-circle >div{
    
    width: 20px;
    height: 20px;
  
    
   } 
   .mouse-over-circle{
    opacity: 0;

   }
   .card-body {
  
    top: 40%;
    left: 0;
    font-size: var(--body-font-size-small);
 
   
  }
  
 .card-body ul{
  margin-bottom: var(--margin-bentoy);
 
 }
 .card-body ul li{
   margin-bottom: var(--margin-bento);
  
 }
  



}
@media (width<=380px){
 
  .animated-card-section h2{
    width: 90%;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    width: 100%;
    gap: 0;
 

    }
    .card>div:first-child{
     
      min-height: 50%;
      width: 90%;
      margin-right: 10%;

    }
    .card-content{
  
      width: 100%;
      margin: auto;
    }
    .card-subtitle{
      font-size: var(--body-font-size-small);
    }
    .card-title{
      margin-bottom: var(--margin-medium);
    }
  
 
 

  .progress-circle{
 
    width: 50%;
   
  }
  .progress-circle >div{
    
    width: 20px;
    height: 20px;
  
    
   } 
   .mouse-over-circle{
    opacity: 0;

   }
   .card-body {
  
    top: 40%;
    left: 0;
    font-size: var(--body-font-size-tiny);
 
   
  }
  
 .card-body ul{
  margin-bottom: var(--margin-bentoy);
 
 }
 .card-body ul li{
   margin-bottom: var(--margin-bento);
  
 }
  



}



